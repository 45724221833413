import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';

import * as talentAlertApi from "../../../api/talentAlertApi";

import { BlockHeader } from './../../../components/LayoutComponents/BlockHeader';
import CloudinaryWidget from './../../../components/CloudinaryWidget';
import VideoModal from './../../CandidateCampaignDetail/CandidateDetails/VideoUploadModal';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  }
}));

const CampaignVideoUpload = ({ video, isVideoProcessing, campaignId }) => {

  const classes = useStyles();
  const [t] = useTranslation(['newTranslations', 'tooltips']);
  const { enqueueSnackbar } = useSnackbar();

  const [modal, setModal] = useState(false);
  const [campaignVideo, setCampaignVideo] = useState(video ? video : null);

  const toggleModal = () => {
    setModal((prevState) => !prevState);
  };

  const handleUploadVideo = (videoInfo) => {
    const uploadOptions = {
      params: {
        campaignId: campaignId
      }
    };

    const data = {
      secure_url: videoInfo.secure_url,
      original_filename: videoInfo.original_filename,
      resource_type: videoInfo.resource_type,
      public_id: videoInfo.public_id,
      duration: videoInfo.duration
    };

    talentAlertApi.cloudinaryWidgetUploadTalentAlertVideo(data, uploadOptions)
      .then(response => {
        setCampaignVideo(response.data);
      })
      .catch(() => {
        enqueueSnackbar('Error during upload, please try again!', { variant: 'error' });
      })
      .then(() => {
        toggleModal();
      });
  };

  return (
    <Card className={classes.root}>
      <BlockHeader
        card
        header={t("newTranslations:landingPageVideo")}
        headerTooltip={t("tooltips:landingPageVideoTooltip")} />
      
      <CardContent>
        {
          isVideoProcessing ? (
            <>{ t("newTranslations:videoProcessing") }</>
          ) : (
            <>
              <CloudinaryWidget onSuccess={handleUploadVideo} talentAlert />
              
              {
                campaignVideo?.url && (
                  <Button
                    color="primary"
                    fullWidth
                    variant="outlined"
                    size="large"
                    onClick={toggleModal} >
                    {t("campaigns:viewVideo")}
                  </Button>
                )
              }
            </>
          )
        }
        
        <VideoModal
          open={modal}
          video={campaignVideo}
          onClose={toggleModal}
          campaignId={campaignId}
          modalHeader={ t("newTranslations:landingPageVideo") } />
      </CardContent>
    </Card>
  );

};

export default CampaignVideoUpload;
