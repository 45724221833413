import React from 'react';

import { CircularProgress, Container, Typography, Grid } from '@material-ui/core';

import CandidateDetails from '../../../../components/CandidateDetails';
import CampaignCandidateExecutiveSummary from '../../../CandidateProfile/CampaignCandidateExecutiveSummary';
import CandidateKeyFacts from './CandidateKeyFacts';
import CandidateOverview from './CandidateOverview';
import CandidateKCQs from './../../../CandidateProfile/CandidateKCQs';

const ProfileDetails = ({
  campaignId,
  consultancyId,
  candidateId,
  candidateCampaign,
  isLoading,
  handleSummaryChange,
  executiveSummary,
  handleKeyFactsChange,
  keyFacts,
  overviews,
  talentAlert
}) => {
  if (isLoading) {
    return (
      <Container maxWidth='xl' style={{ marginTop: '50px' }}>
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      </Container>
    );
  }

  return (
    <>
      <Container maxWidth='xl' style={{ marginTop: '50px' }}>
        <Grid container spacing={3}>
          {
            !talentAlert && (
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant='h1'>
                      {
                        candidateCampaign && candidateCampaign.firstName ?
                        `${candidateCampaign.firstName} ${candidateCampaign.lastName}` :
                        ''
                      }
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )
          }

          <Grid item xs={12}>
            <CandidateDetails
              consultancyId={consultancyId}
              candidateId={candidateId}
              campaignId={campaignId}
              talentAlert />

            {
              talentAlert && (
                <>
                  <CampaignCandidateExecutiveSummary
                    candidateId={candidateId}
                    campaignId={campaignId}
                    executiveSummary={executiveSummary}
                    handleSummaryChange={handleSummaryChange} />

                  <CandidateKeyFacts
                    candidateId={candidateId}
                    campaignId={campaignId}
                    consultancyId={consultancyId}
                    handleKeyFactsChange={handleKeyFactsChange}
                    keyFacts={keyFacts} />

                  <CandidateOverview
                    candidateId={candidateId}
                    campaignId={campaignId}
                    consultancyId={consultancyId}
                    overviews={overviews} />
                </>
              )
            }

            <CandidateKCQs />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default ProfileDetails;
