import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Button, useTheme } from '@material-ui/core';
import { CameraAltOutlined, CloudUploadOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';

import CloudinaryVideoRecorder from './CloudinaryVideoRecorder';

import { CANDIDATE } from './../../constants/roles';

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  button: {
    width: '48%',
    fontSize: '12px'
  },
  buttonMobile: {
    width: '100%',
    fontSize: '12px'
  },
  icon: {
    width: '16px',
    height: '16px',
    marginRight: '4px'
  }
}));

const CloudinaryWidget = ({ onSuccess, talentAlert }) => {

  const classes = useStyles();

  const theme = useTheme();

  const [t] = useTranslation(['cloudinary']);

  const { enqueueSnackbar } = useSnackbar();

  const [widget, setWidget] = useState({});
  const [isVideoRecorderOpened, setIsVideoRecorderOpened] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  const { roleType } = useSelector(state => state.session);

  const styles = {
    palette: {
      tabIcon: theme.palette.button.main,
      link: theme.palette.button.main,
      action: theme.palette.button.main,
      inProgress: theme.palette.button.main,
      complete: theme.palette.button.main
    }
  };

  const text = {
    en: {
      or: t('cloudinary:cloudinaryWidgetOr'),
      menu: {
        files: t('cloudinary:cloudinaryWidgetMenuFiles')
      },
      local: {
        browse: t('cloudinary:cloudinaryWidgetLocalBrowse'),
        dd_title_single: t('cloudinary:cloudinaryWidgetLocalDdTitleSingle'),
        dd_title_multi: t('cloudinary:cloudinaryWidgetLocalDdTitleMulti'),
        drop_title_single: t('cloudinary:cloudinaryWidgetLocalDropTitleSingle'),
        drop_title_multiple: t('cloudinary:cloudinaryWidgetLocalDropTitleMultiple')
      }
    }
  };

  const widgetOptions = {
    cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
    upload_preset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
    sources: ['local'],
    multiple: false,
    showPoweredBy: false,
    autoMinimize: true,
    styles,
    text
  };

  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobileDevice(true);
      text.en.menu.files = t('cloudinary:cloudinaryWidgetMenuFilesMobile');
    }

    setWidget(
      window.cloudinary.createUploadWidget(widgetOptions, handleUpload)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpload = (err, video) => {
    if (err) {
      console.log(err);
      return;
    }

    if (!video || video.event !== 'success') {
      console.log(t('cloudinary:cloudinaryWidgetErrorUpload'));
      return;
    }

    onSuccess(video.info);
    enqueueSnackbar(t('cloudinary:cloudinaryRecordNotificationUploadSuccess'), { variant: 'success' });
  };

  const openWidget = (event) => {
    event.preventDefault();
    widget.open();
  };

  const toggleVideoRecorder = () => {
    setIsVideoRecorderOpened(state => !state);
  };

  return (
    <>
      <Box className={classes.box} >
        <Button
          color="primary"
          variant="contained"
          className={isMobileDevice || roleType !== CANDIDATE ? classes.buttonMobile : classes.button}
          onClick={openWidget} >
          <CloudUploadOutlined className={classes.icon} />
          {
            isMobileDevice ?
              t('cloudinary:cloudinaryWidgetMobileBtn') :
              t('cloudinary:cloudinaryWidgetBtn')
          }
        </Button>

        {
          talentAlert ? (
            !isMobileDevice && (
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={toggleVideoRecorder} >
                <CameraAltOutlined className={classes.icon} />
                {t('cloudinary:cloudinaryRecordVideoBtn')}
              </Button>
            )
          ) : (
            !isMobileDevice && roleType === CANDIDATE && (
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={toggleVideoRecorder} >
                <CameraAltOutlined className={classes.icon} />
                {t('cloudinary:cloudinaryRecordVideoBtn')}
              </Button>
            )
          )
        }
      </Box>

      {
        talentAlert ? (
          !isMobileDevice && isVideoRecorderOpened && (
            <CloudinaryVideoRecorder isOpen={isVideoRecorderOpened} onClose={toggleVideoRecorder} talentAlert />
          )
        ) : (
          !isMobileDevice && roleType === CANDIDATE && isVideoRecorderOpened && (
            <CloudinaryVideoRecorder isOpen={isVideoRecorderOpened} onClose={toggleVideoRecorder} />
          )
        )
      }
    </>
  );

};

export default CloudinaryWidget;
