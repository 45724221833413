import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, Card, CardContent } from "@material-ui/core";
import CustomFileDropzone from "src/components/CustomFileDropzone";
import iintroLogo from "src/assets/images/logos/i-intro_logo.png";
import taLogo from './../../../assets/images/logos/i-intro_ta_subscription_logo.png';
import taProLogo from './../../../assets/images/logos/i-intro_ta_pro_subscription_logo.png';
import CampaignInformation from "./CampaignInformation";
import CampaignInformationBlocks from "./CampaignInformationBlocks";
import ContactsCard from "./ContactCard/ContactsCard";
import FeesCard from "./FeesCard/FeesCard";
import VideoAssessmentSetup from "../../../components/VideoAssessment/VideoAssessmentSetup";
import CampaignTermsAndConditions from "./TermsAndConditions/CampaignTermsAndConditions";
import CampaignDocuments from "./CampaignDocuments";
import BgRequirements from "./BgRequirements";
import CampaignMcQuaig from "./CampaignMcQuaig";
import CampaignStatus from "./CampaignStatus";
import { uploadCampaignLogo } from "src/api/campaignApi";
import { useSnackbar } from "notistack";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import * as CampaignApi from "src/api/campaignApi";
import * as campaignActions from "src/actions/campaignActions";

import { INFORMATION_BLOCKS_ID, MC_QUAIG_ID, VIDEO_ASSESSMENT_ID } from './../../../constants/moduleIds';
import * as subscriptionPlansIds from './../../../constants/subscriptionPlansIds';

//TODO:Translate this page

function CampaignDetails({ guestView, toggleContactView }) {
  const [t] = useTranslation([
    "common",
    "validation",
    "campaigns",
    "tooltips",
  ]);
  const { enqueueSnackbar } = useSnackbar();
  let {
    campaignRequiredDocuments,
    campaignModuleIds,
    campaignLogo,
    id,
  } = useSelector((state) => state.campaign);
  const { consultancyId } = useSelector((state) => state.session);
  const consultancySubscriptionPlanId = useSelector((state) => state.session.consultancySubscriptionPlanId);
  
  const [logo, setLogo] = useState(campaignLogo);

  const dispatch = useDispatch();

  const handleUploadCampaignLogo = (value) => {
    return uploadCampaignLogo(consultancyId, id, value)
      .then((response) => {
        if (response.status === 200) {
          CampaignApi.getCampaignDetails(consultancyId, id).then((response) => {
            dispatch({
              type: campaignActions.UPDATE_CAMPAIGN,
              campaign: response.data,
            });
          });
          enqueueSnackbar(t("common:campaignImageUpdate"), {
            variant: "success",
          });
          setLogo(response.data);
        } else {
          throw Error("Failed to update profile picture");
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("common:campaignImageUpdateError"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const getLogo = () => {
    if (logo) {
      return logo;
    }

    switch (consultancySubscriptionPlanId) {
      case subscriptionPlansIds.TA:
        return taLogo;
      case subscriptionPlansIds.TA_PRO:
        return taProLogo;
      default:
        return iintroLogo;
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}></Grid>
      <Grid item xs={12} lg={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} style={{ textAlign: "left" }}>
                    {guestView ? (
                      <img
                        style={{ maxHeight: "100px", maxWidth: "100%" }}
                        src={ getLogo() }
                        alt=""
                      />
                    ) : (
                      <>
                        <BlockHeader
                          headerTooltip={t("tooltips:campaignUploadLogo")}
                          card
                          header={t("labels:manageLogo")}
                        />
                        <CustomFileDropzone
                          type="single"
                          format="image"
                          uploadFunction={handleUploadCampaignLogo}
                          placeHolderImage={ getLogo() }
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <CampaignStatus guestView={guestView} />
              </CardContent>
            </Card>
          </Grid>
          {campaignModuleIds.includes(VIDEO_ASSESSMENT_ID) &&
            !guestView && (
              <Grid item xs={12}>
                <VideoAssessmentSetup />
              </Grid>
            )}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <CampaignDocuments
                  campaignRequiredDocuments={campaignRequiredDocuments}
                  campaignModuleIds={campaignModuleIds}
                  guestView={guestView}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={5}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CampaignInformation guestView={guestView} />
          </Grid>
          {campaignModuleIds?.includes(INFORMATION_BLOCKS_ID) && (
            <Grid item xs={12}>
              <CampaignInformationBlocks guestView={guestView} />
            </Grid>
          )}
          {campaignModuleIds?.includes(MC_QUAIG_ID) && (
            <Grid item xs={12}>
              <CampaignMcQuaig guestView={guestView} />
            </Grid>
          )}
          <Grid item xs={12}>
            <CampaignTermsAndConditions guestView={guestView} />
          </Grid>
          <Grid item xs={12}>
            <BgRequirements guestView={guestView} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ContactsCard guestView={guestView} />
          </Grid>
          <Grid item xs={12}>
            <FeesCard />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CampaignDetails;
