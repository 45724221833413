import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";

import { useSnackbar } from 'notistack';

import CustomFileDropzone from "src/components/CustomFileDropzone";
import CountrySelect from "src/components/CountrySelect";
import { useTranslation } from "react-i18next";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import { CardButton } from "src/components/LayoutComponents/CardButton";

import ChangeEmail from './../../../../components/ChangeEmail';
import ChangePassword from './../../../../components/ChangePassword';

import * as candidateApi from './../../../../api/candidateApi';

const useStyles = makeStyles((theme) => ({
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  gridRow: {
    marginTop: "20px",
  },
  consultancyLogo: {
    height: "50px",
    width: "auto",
  },
  inputRow: {
    alignItems: "center",
  },
  headerRow: {
    textAlign: "right",
    padding: "0px 12px",
  },
}));

function DefaultCandidateProfile({
  isProfileView,
  handleUploadProfilePicture,
  toggleAccount,
  enabled,
  saveDetails,
  handleChange,
  hasError,
  handleCountryChange,
  formState,
  candidateId,
  consultancyId
}) {
  const classes = useStyles();
  const [t] = useTranslation(["common", "validation", "snackbar", "labels", "changeEmail", "changePassword"]);
  const { enqueueSnackbar } = useSnackbar();

  const roleType = useSelector((state) => {
    return state.session.roleType;
  });

  const changeEmail = (email) => {
    return candidateApi.changeEmail(candidateId, email)
      .then(() => {
        enqueueSnackbar(t('changeEmail:notificationSuccessEmailChange'), { variant: 'success' });
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(t('changeEmail:notificationErrorEmailChange'), { variant: 'error' });
      });
  };

  const changePassword = (password) => {
    return candidateApi.changePassword(candidateId, consultancyId, password)
      .then(() => {
        enqueueSnackbar(t('changePassword:notificationSuccessPasswordChange'), { variant: 'success' });
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(t('changePassword:notificationErrorPasswordChange'), { variant: 'error' });
      });
  };

  const isUserAdmin =
    roleType?.toLowerCase() === "admin" ||
    roleType?.toLowerCase() === "coreadmin" ||
    roleType?.toLowerCase() === "sysadmin";
  return (
    <Grid container spacing={3} style={{ marginTop: 16 }}>
      <Grid item xs={12} lg={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <BlockHeader reduceMargin header={t("common:manageAvatar")} />
                <CustomFileDropzone
                  type="single"
                  format="image"
                  uploadFunction={handleUploadProfilePicture}
                  placeHolderImage={
                    formState.values.profilePhoto ||
                    "https://www.dovercourt.org/wp-content/uploads/2019/11/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.jpg"
                  }
                />
              </CardContent>
            </Card>
          </Grid>

          {!isProfileView && isUserAdmin && (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <BlockHeader
                    defeatMargin
                    header={t("labels:manageAccountStatus")}
                  />
                  <Grid item xs={12}>
                    <Button
                      className={classes.submitButton}
                      color="primary"
                      size="large"
                      variant="outlined"
                      type="submit"
                      onClick={toggleAccount}
                    >
                      {enabled
                        ? t("common:disableAccount")
                        : t("common:enableAccount")}
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>


        <ChangeEmail changeEmail={ changeEmail } consultancyId={ consultancyId } candidateId={ candidateId } />

        <ChangePassword changePassword={ changePassword } candidateId={ candidateId } />
      </Grid>
      <Grid item xs={12} lg={9}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <BlockHeader card header={t("labels:manageDetails")} />
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:salutationLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="salutation"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("salutation")}
                          value={formState.values.salutation || ""}
                          helperText={
                            hasError("salutation")
                              ? t(
                                  `validation:${formState.errors.salutation[0]}`
                                )
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:firstNameLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="firstName"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("firstName")}
                          value={formState.values.firstName || ""}
                          helperText={
                            hasError("firstName")
                              ? t(`validation:${formState.errors.firstName[0]}`)
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:lastNameLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="lastName"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("lastName")}
                          value={formState.values.lastName || ""}
                          helperText={
                            hasError("lastName")
                              ? t(`validation:${formState.errors.lastName[0]}`)
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:telephoneLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="telephone"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("telephone")}
                          value={formState.values.telephone || ""}
                          helperText={
                            hasError("telephone")
                              ? t(`validation:${formState.errors.telephone[0]}`)
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:addressLabel1`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="addressLine1"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("addressLine1")}
                          value={formState.values.addressLine1 || ""}
                          helperText={
                            hasError("addressLine1")
                              ? t(
                                  `validation:${formState.errors.addressLine1[0]}`
                                )
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:addressLabel2`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="addressLine2"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("addressLine2")}
                          value={formState.values.addressLine2 || ""}
                          helperText={
                            hasError("addressLine2")
                              ? t(
                                  `validation:${formState.errors.addressLine2[0]}`
                                )
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">{t(`common:City`)}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          name="city"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("city")}
                          value={formState.values.city || ""}
                          helperText={
                            hasError("city")
                              ? t(`validation:${formState.errors.city[0]}`)
                              : null
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography variant="h5">
                          {t(`common:postcodeLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          fullWidth
                          name="postalCode"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("postalCode")}
                          value={formState.values.postalCode || ""}
                          helperText={
                            hasError("postalCode")
                              ? t(
                                  `validation:${formState.errors.postalCode[0]}`
                                )
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:countryLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <CountrySelect
                          handleCountryChange={handleCountryChange}
                          value={formState.values.country}
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.headerRow}>
                        <CardButton
                          defeatMargin
                          save
                          btnFunc={saveDetails}
                          btnDisabled={!formState.isValid}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DefaultCandidateProfile;
