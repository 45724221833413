import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import {
  CardContent,
  Card,
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import EditTermsAndConditionsModal from "./EditTermsAndConditionsModal";
import * as CampaignApi from "src/api/campaignApi";
import { UPDATE_CAMPAIGN_TCS } from "src/actions/campaignActions";
import { TabPanel } from "src/components/TabPanel";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import { CardButton } from "src/components/LayoutComponents/CardButton";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  termsAndConditionsCard: {
    paddingTop: "0px",
    overflowY: "auto",
    height: "400px",
    fontFamily: "sans-serif",
  },
  contactListHeader: {
    display: "flex",
    marginRight: 20,
    justifyContent: "space-between",
    alignItems: "center",
  },
  editButton: {
    textAlign: "center",
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
}));

function CampaignTermsAndConditions({ guestView }) {
  const classes = useStyles();
  const [t] = useTranslation(["common", "validation", "campaigns", "tooltips"]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [currentCampaignTCTab, setCurrentCampaignTCTab] = useState(0);
  const tabs = [
    { value: 0, label: t("common:candidate") },
    { value: 1, label: t("common:contact") },
  ];
  const handleCampaignTCTabChange = (event, value) => {
    setCurrentCampaignTCTab(value);
  };

  const campaign = useSelector((state) => state.campaign);

  const [
    candidateTermsAndConditionsOpen,
    setCandidateTermsAndConditionsOpen,
  ] = useState(false);

  const handleCandidateTermsAndConditionsModalOpen = () => {
    setCandidateTermsAndConditionsOpen(true);
  };

  const handleCandidateTermsAndConditionsModalClose = () => {
    setCandidateTermsAndConditionsOpen(false);
  };

  const onCandidateTermsAndConditionsUpdate = (termsAndConditions) => {
    return CampaignApi.updateCampaignTermsAndConditions(
      campaign.id,
      termsAndConditions,
      "Candidate"
    )
      .then((response) => {
        dispatch({
          type: UPDATE_CAMPAIGN_TCS,
          termsAndConditions: response.data,
        });
        enqueueSnackbar(`${t("snackbar:termsAndConditionSuccess")}`, {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar(`${t("snackbar:termsAndConditionFailure")}`, {
          variant: "error",
        });
      });
  };

  const [
    contactTermsAndConditionsOpen,
    setContactTermsAndConditionsOpen,
  ] = useState(false);

  const handleContactTermsAndConditionsModalOpen = () => {
    setContactTermsAndConditionsOpen(true);
  };

  const handleContactTermsAndConditionsModalClose = () => {
    setContactTermsAndConditionsOpen(false);
  };

  const onContactTermsAndConditionsUpdate = (termsAndConditions) => {
    return CampaignApi.updateCampaignTermsAndConditions(
      campaign.id,
      termsAndConditions,
      "Contact"
    )
      .then((response) => {
        dispatch({
          type: UPDATE_CAMPAIGN_TCS,
          termsAndConditions: response.data,
        });
        enqueueSnackbar(`${t("snackbar:termsAndConditionSuccess")}`, {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar(`${t("snackbar:termsAndConditionFailure")}`, {
          variant: "error",
        });
      });
  };

  return (
    <>
      <Card>
        <BlockHeader
          card
          padding
          header={t("campaigns:termsAndConditionsLabel")}
          headerTooltip={t("tooltips:editTermsAndConditions")}
        />
        <CardContent>
          <AppBar position="static" className={classes.appBar}>
            <Tabs
              value={currentCampaignTCTab}
              onChange={handleCampaignTCTabChange}
              variant="scrollable"
              textColor="primary"
              className={classes.tabBar}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={t(`common:${tab.label}`)}
                  value={tab.value}
                  className={classes.tab}
                />
              ))}
            </Tabs>
          </AppBar>
          <TabPanel value={currentCampaignTCTab} index={0}>
            <div className={classes.termsAndConditionsCard}>
              <div class="ql-editor">
                {parse(
                  campaign?.campaignCandidateTCs?.termsAndConditions || ""
                )}
              </div>
            </div>
            {!guestView && (
              <div className={classes.editButton}>
                <CardButton
                  btnFunc={handleCandidateTermsAndConditionsModalOpen}
                  btnLabel={t("common:edit")}
                />
              </div>
            )}
          </TabPanel>
          <TabPanel value={currentCampaignTCTab} index={1}>
            <div className={classes.termsAndConditionsCard}>
              <div class="ql-editor">
                {parse(campaign.campaignContactTCs.termsAndConditions || "")}
              </div>
            </div>
            {!guestView && (
              <div className={classes.editButton}>
                <CardButton
                  btnFunc={handleContactTermsAndConditionsModalOpen}
                  btnLabel={t("common:edit")}
                />
              </div>
            )}
          </TabPanel>
        </CardContent>
      </Card>
      <EditTermsAndConditionsModal
        onSuccess={onCandidateTermsAndConditionsUpdate}
        open={candidateTermsAndConditionsOpen}
        handleClose={handleCandidateTermsAndConditionsModalClose}
        campaignTC={campaign.campaignCandidateTCs?.termsAndConditions || ""}
        consultancyTC={
          campaign.defaultCampaignCandidateTCs?.termsAndConditions || ""
        }
      />
      <EditTermsAndConditionsModal
        onSuccess={onContactTermsAndConditionsUpdate}
        open={contactTermsAndConditionsOpen}
        handleClose={handleContactTermsAndConditionsModalClose}
        campaignTC={campaign.campaignContactTCs?.termsAndConditions || ""}
        consultancyTC={
          campaign.defaultCampaignContactTCs?.termsAndConditions || ""
        }
      />
    </>
  );
}
export default CampaignTermsAndConditions;
