import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppBar, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import * as candidateApi from './../../api/candidateApi';

import * as actionTypes from './../../actions';
import { SET_CANDIDATE_CAMPAIGN } from './../../actions/candidateCampaignActions';

import CandidateKCQs from './CandidateKCQs';
import CandidateMcQuaig from './CandidateMcQuaig';
import CampaignCandidateCareerHighlights from './CampaignCandidateCareerHighlights';
import CampaignCandidateExecutiveSummary from './CampaignCandidateExecutiveSummary';
import BarChart from './../../components/BarChart';
import CandidateComments from './../../components/CandidateComments';
import CandidateDetails from './../../components/CandidateDetails';
import { TabPanel } from './../../components/TabPanel';
import { ContentWrapper } from './../../components/LayoutComponents/ContentWrapper';
import CustomTabs from './../../components/LayoutComponents/CustomTabs';
import PageBackButton from './../../components/LayoutComponents/PageBackButton';
import { PageTitle } from './../../components/LayoutComponents/PageTitle';

import ContactPreviewCandidateProfile from './../ContactPreviewCandidateProfile';

import { useCampaignOrVacancyTranslationPrefix } from './../../utils/useSubscriptionPrefix';

import { TA_PRO } from './../../constants/subscriptionPlansIds';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  gridRow: {
    marginTop: '20px'
  },
  appBar: {
    boxShadow: 'none',
    backgroundColor: 'transparent'
  },
  tab: {
    color: theme.palette.primary.main
  }
}));

const CandidateProfile = ({ candidateId, campaignId, ...rest }) => {

  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();

  const classes = useStyles();

  const history = useHistory();

  const [t] = useTranslation(['campaigns', 'common', 'snackbar', 'vacancies']);

  const dispatch = useDispatch();
  
  const timer = useRef(null);

  const candidateCampaign = useSelector(state => state.candidateCampaign.candidateProfile);
  const campaign = useSelector(state => state.campaign);
  const { consultancyId, consultancySubscriptionPlanId } = useSelector(state => state.session);

  const [breadcrumbs, setBreadCrumbs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [executiveSummary, setExecutiveSummary] = useState();
  const [careerHighlights, setCareerHighlights] = useState();

  const tabs = [{ value: 0, label: t("candidates:candidateProfile") }];

  if (consultancySubscriptionPlanId !== TA_PRO) {
    tabs.push({ value: 1, label: t("campaigns:Comments") });
  }

  useEffect(() => {
    getCandidateProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultancyId, candidateId, campaignId]);

  useEffect(() => {
    setBreadCrumbs([
      { title: t(`${campaignsPrefix}:campaigns`), link: '' },
      {
        title: candidateCampaign?.campaignTitle,
        link: consultancySubscriptionPlanId === TA_PRO ? `/vacancies/${candidateCampaign?.campaignId}` : `/campaigns/${candidateCampaign?.campaignId}`
      },
      {
        title: `${candidateCampaign?.firstName} ${candidateCampaign?.lastName}`,
        link: consultancySubscriptionPlanId === TA_PRO ?
          `/vacancies/${candidateCampaign?.campaignId}/candidates/${candidateCampaign?.candidateId}` :
          `/campaigns/${candidateCampaign?.campaignId}/candidates/${candidateCampaign?.candidateId}`
      }
    ]);
  }, [candidateCampaign]);

  const getCandidateProfile = () => {
    candidateApi.getCandidateProfile(consultancyId, candidateId, campaignId)
      .then((response) => {
        dispatch({
          type: SET_CANDIDATE_CAMPAIGN,
          candidateCampaign: {
            candidateProfile: response.data
          }
        });

        setExecutiveSummary(response.data.candidateExecutiveSummary);
        setCareerHighlights(response.data.candidateCareerHighlights);
      })
      .catch(() => {
        history.push('/dashboard');
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const getBarChartCatergories = () => {
    if (candidateCampaign?.suitabilityScores?.categories) {
      return candidateCampaign.suitabilityScores.categories;
    }

    return [];
  }

  const getBarChartSeries = () => {
    if (candidateCampaign && candidateCampaign.suitabilityScores && candidateCampaign.suitabilityScores.series) {
      return candidateCampaign.suitabilityScores.series;
    }

    return [];
  }

  const handleChange = (value) => {
    setCurrentTab(value);
  };

  const handleSummaryChange = (event) => {
    //prevents from updating state with each typed letter. After user stops typing it waits for half second and updates state.
    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(() => setExecutiveSummary(event), 500);
  };

  const handleCareerHighlightsChange = (event) => {
    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(() => setCareerHighlights(event), 500);
  };

  const toggleContactPreview = () => {
    dispatch({ type: actionTypes.TOGGLE_ADMIN_CONTACT_PREVIEW });
  };

  if (campaign?.adminContactPreview) {
    return (
      <ContactPreviewCandidateProfile campaignId={campaignId} candidateId={candidateId} />
    );
  }

  return (
    <div style={{ overflow: "hidden", paddingBottom: "20px" }}>
      <PageTitle
        breadcrumbs={breadcrumbs}
        btnLabel={t("campaigns:viewAsClient")}
        btnFunc={() => toggleContactPreview()} />

      <ContentWrapper loading={isLoading}>
        <PageBackButton />

        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.gridRow}>
            <AppBar position="static" className={classes.appBar}>
              <CustomTabs tabs={ tabs } onChange={ handleChange } tabClassName={ classes.tab } />
            </AppBar>
          </Grid>

          <Grid item xs={12} className={classes.gridRow}>
            <TabPanel value={currentTab} index={0}>
              <CandidateDetails guestView={rest?.guestView} campaignId={ campaignId } candidateId={ candidateId } consultancyId={ consultancyId } />

              <CampaignCandidateExecutiveSummary
                candidateId={candidateId}
                campaignId={campaignId}
                executiveSummary={executiveSummary}
                handleSummaryChange={handleSummaryChange} />

              <CampaignCandidateCareerHighlights
                candidateId={candidateId}
                campaignId={campaignId}
                careerHighlights={careerHighlights}
                handleCareerHighlightsChange={handleCareerHighlightsChange} />

              {
                consultancySubscriptionPlanId !== TA_PRO && (
                  <CandidateKCQs />
                )
              }

              <BarChart
                title={t("campaigns:suitabilityChart")}
                categories={ getBarChartCatergories() }
                series={ getBarChartSeries() } />

              {
                candidateCampaign?.campaignMcQuaig !== "disabled" && (
                  <CandidateMcQuaig guestView={rest?.guestView} />
                )
              }
            </TabPanel>

            {
              consultancySubscriptionPlanId !== TA_PRO && (
                <TabPanel value={currentTab} index={1}>
                  <CandidateComments
                    candidateCampaignId={candidateCampaign?.candidateId}
                    consultancyId={consultancyId}
                    campaignId={campaignId}
                    comments={candidateCampaign?.comments}
                    guestView={rest?.guestView} />
                </TabPanel>
              )
            }
          </Grid>
        </Grid>
      </ContentWrapper>
    </div>
  );

}

export default CandidateProfile;
