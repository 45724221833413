import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import getZohoDesk from './../utils/getZohoDesk';

import * as subscriptionPlansIds from './../constants/subscriptionPlansIds';


const ZohoHelpDesk = () => {

  const role = useSelector((state) => state.session.roleType);
  const consultancySubscriptionPlanId = useSelector((state) => state.session.consultancySubscriptionPlanId);

  //Remove the Zoho Component if we leave the current page (This is so that the component is removed when you lose your role. There may be a better way to do it)
  useEffect(() => {
    return () => {
      const zohoScript = document.querySelector(`aside[id="zohohc-asap-web-helper-main"]`)
      if (zohoScript) zohoScript.remove();
    }
  });

  if (consultancySubscriptionPlanId === subscriptionPlansIds.TA || consultancySubscriptionPlanId === subscriptionPlansIds.TA_PRO) {
    return null;
  }

  return (
    <Helmet>
      <script
        name="zoho_desk_script"
        async
        type="text/javascript"
        src={getZohoDesk(role)}
      />

    </Helmet>
  );
};

export default ZohoHelpDesk;