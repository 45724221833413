import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AppBar, Grid } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/EmailSharp';
import GroupIcon from '@material-ui/icons/Group';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import SecurityIcon from '@material-ui/icons/Security';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';

import validate from 'validate.js';

import * as notificationApi from './../../api/notificationApi';
import * as sessionApi from './../../api/sessionApi';


import { UPDATE_NOTIFICATION_SETTINGS, UPDATE_TYPE_SETTINGS, UPDATE_EMAIL_SETTINGS } from './../../actions';
import { UPDATE_PROFILE_PICTURE, LANGUAGE_CHANGE } from './../../actions/sessionActions';
import { setCookie } from './../../actions/cookieActions';

import EmailsAndNotifications from './EmailsAndNotifications';
import EmailSignature from './EmailSignature';
import GeneralProfile from './generalProfile';
import Groups from './Groups';
import UserSecurity from './UserSecurity';

import { ContentWrapper } from './../../components/LayoutComponents/ContentWrapper';
import CustomTabs from './../../components/LayoutComponents/CustomTabs';
import { PageTitle } from './../../components/LayoutComponents/PageTitle';
import { ReturnButton } from './../../components/LayoutComponents/ReturnButton';
import { TabPanel } from './../../components/TabPanel';
import KCQSettings from './../ConsultancyManagement/Settings/KCQSettings/questionSetSettings';
import DefaultCandidateProfile from './../DefaultCandidateProfile/DefaultCandidateProfile';

import * as roles from './../../constants/roles';
import * as localeSelection from './../../constants/localeSelection';
import * as subscriptionPlansIds from './../../constants/subscriptionPlansIds';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    overflow: 'hidden',
    paddingBottom: '20px'
  },
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1)
    }
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  modalCard: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      transform: `translate(${0}%, ${50}%)`
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
      transform: `translate(${50}%, ${50}%)`
    },
    '&:focus': {
      outline: 'none'
    }
  },
  gridRow: {
    marginTop: '20px'
  },
  appBar: {
    position: 'static',
    boxShadow: 'none',
    backgroundColor: 'transparent'
  },
  tabDisabled: {
    display: 'none'
  },
  tabIcon: {
    fontSize: '1.4rem'
  }
}));

const MyProfile = () => {

  const classes = useStyles();
  
  const [t, i18n] = useTranslation(['common', 'snackbar']);

  const dispatch = useDispatch();
  
  const { enqueueSnackbar } = useSnackbar();

  const [currentTab, setCurrentTab] = useState(0);
  const [profileData, setProfileData] = useState();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const role = useSelector((state) => state.session.roleType);
  const consultancyId = useSelector((state) => state.session.consultancyId);
  const roleType = useSelector((state) => state?.session?.roleType);
  const consultancySubscriptionPlanId = useSelector((state) => state.session.consultancySubscriptionPlanId);

  const schema = {
    firstName: {
      presence: { allowEmpty: false, message: t('common:required') },
      length: {
        maximum: 64
      }
    },
    lastName: {
      presence: { allowEmpty: false, message: t('common:required') },
      length: {
        maximum: 64
      }
    },
    country: {
      presence: { allowEmpty: false, message: t('common:required') }
    }
  };

  const tabs = [
    {
      value: 2,
      label: t('common:Emails & Notifications'),
      icon: <EmailIcon className={classes.tabIcon} />
    },
    {
      value: 4,
      label: t('common:kcqSets'),
      icon: <LibraryBooksIcon className={classes.tabIcon} ></LibraryBooksIcon>
    }
  ];

  if ((role && (role === roles.CONTACT || role === roles.CANDIDATE)) || consultancySubscriptionPlanId === subscriptionPlansIds.TA_PRO) {
    tabs.pop();
  }

  if (role && role !== roles.CONTACT) {
    tabs.splice(0, 0, {
      value: 0,
      label: t('common:General'),
      icon: <AccountCircleIcon className={classes.tabIcon} />
    });

    tabs.splice(3, 0, {
      value: 3,
      label: t('common:Security'),
      icon: <SecurityIcon className={classes.tabIcon} />
    });
  }

  if (role && (role === roles.CONSULTANT || role === roles.ADMIN)) {
    tabs.splice(1, 0, {
      value: 1,
      label: t('common:Groups'),
      icon: <GroupIcon className={classes.tabIcon} />
    });
  }

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getProfile();
    setTypes();
    setNotificationSettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  const getProfile = () => {
    sessionApi.getProfile()
      .then((response) => {
        setProfileData(response.data);

        setFormState((prevValues) => ({
          ...prevValues,
          values: {
            ...response.data
          }
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const setTypes = () => {
    notificationApi.getNotificationTypes(roleType, consultancySubscriptionPlanId)
      .then((types) => {
        if (types) {
          setNotificationTypesArray(types.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const setNotificationSettings = () => {
    notificationApi.getUserNotificationSettings()
      .then((settings) => {
        if (settings) {
          setNotificationAndEmailSettings(settings);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const setNotificationSettingsArray = (settings) => {
    dispatch({
      type: UPDATE_NOTIFICATION_SETTINGS,
      payload: settings
    });
  };

  const setEmailSettingsArray = (settings) => {
    dispatch({
      type: UPDATE_EMAIL_SETTINGS,
      payload: settings
    });
  };

  const setNotificationAndEmailSettings = (settings) => {
    if (settings?.data[0]) {
      setNotificationSettingsArray(settings.data[0].notificationIds) &&
      setEmailSettingsArray(settings.data[0].emailNotificationIds)
    } else {
      setNotificationSettingsArray([0]) && setEmailSettingsArray([0]);
    }
  };

  const setNotificationTypesArray = (types) => {
    dispatch({
      type: UPDATE_TYPE_SETTINGS,
      payload: types
    });
  };

  const handleTabChange = (value) => {
    setCurrentTab(value);
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleCountryChange = (value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        country: value
      },
      touched: {
        ...prevFormState.touched,
        country: true
      }
    }));
  };

  const saveDetails = (event) => {
    event.preventDefault();

    sessionApi.updateUserProfile(formState.values)
      .then(() => {
        dispatch({
          type: LANGUAGE_CHANGE,
          language: formState.values.languageKey
        });

        i18n.changeLanguage(formState.values.languageKey);
        setCookie('language', formState.values.languageKey);

        if (formState.values.userId) {
          enqueueSnackbar(t('snackbar:updateUserProfile'), { variant: 'success' });
          return;
        }

        enqueueSnackbar(t('snackbar:updateProfile'), { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar(t('snackbar:failedToUpdateProfile'), { variant: 'error' });
      });
  };

  const handleUploadProfilePicture = (value) => {
    sessionApi.uploadProfilePicture(value)
      .then((response) => {
        if (response.status !== 200) {
          throw Error("Failed to update logo");
        }

        enqueueSnackbar(t('snackbar:profilePictureUpdated'), { variant: 'success' });

        setFormState((prevFormState) => ({
          ...prevFormState,
          values: {
            ...prevFormState.values,
            profilePicture: response.data
          }
        }));

        dispatch({
          type: UPDATE_PROFILE_PICTURE,
          profilePicture: response.data
        });
      })
      .catch((error) => {
        console.error(error);

        enqueueSnackbar(t('snackbar:profilePictureUpdatedError'), { variant: 'error' });
      });
  };

  const handleLocaleChange = (value) => {
    const languageKey = localeSelection.localeOptions.find(lo => lo.id === value.props.value)?.languageKey;

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        localeId: value.props.value,
        languageKey: languageKey
      },
      touched: {
        ...prevFormState.touched,
        localeId: true
      }
    }));
  };

  return (
    <div className={ classes.wrapper } >
      <PageTitle pageTitle={ t("common:profileManagementTitle") } />

      <ContentWrapper>
        <Grid container spacing={ 3 } >
          <Grid item xs={ 12 } className={ classes.gridRow } >
            <AppBar className={ classes.appBar }>
              <CustomTabs tabs={ tabs } onChange={ handleTabChange } tabClassName={ classes.tab } />
            </AppBar>
          </Grid>

          <Grid item xs={ 12 } className={ classes.gridRow } >
            {
              role && role !== roles.CONTACT && (
                <TabPanel value={ currentTab } index={ 0 } >
                  {
                    profileData && role !== roles.CANDIDATE ? (
                      <GeneralProfile
                        profileData={ profileData }
                        isProfileView={ true }
                        consultancyId={ consultancyId }
                        formState={ formState }
                        handleUploadProfilePicture={ handleUploadProfilePicture }
                        handleChange={ handleChange }
                        hasError={ hasError }
                        handleCountryChange={ handleCountryChange }
                        saveDetails={ saveDetails }
                        handleLocaleChange={ handleLocaleChange } />
                    ) : profileData && role === roles.CANDIDATE ? (
                      <DefaultCandidateProfile />
                    ) : null
                  }
    
                  {
                    (roleType === roles.CONSULTANT || roleType === roles.ADMIN) && (
                      <EmailSignature />
                    )
                  }
                </TabPanel>
              )
            }

            <TabPanel value={ currentTab } index={ role && role === roles.CONTACT ? 0 : 2 } >
              <EmailsAndNotifications />
            </TabPanel>

            {
              role && role !== roles.CONTACT && (
                <TabPanel value={ currentTab } index={ 3 } >
                  <UserSecurity profileData={profileData} />
                </TabPanel>
              ) 
            }

            {
              role && (role === roles.CONSULTANT || role === roles.ADMIN) && (
                <>
                  <TabPanel value={ currentTab } index={ 1 } >
                    <Groups />
                  </TabPanel>

                  {
                    consultancySubscriptionPlanId !== subscriptionPlansIds.TA_PRO && (
                      <TabPanel value={ currentTab } index={ 4 } >
                        <KCQSettings consultancyId={consultancyId} />
                      </TabPanel>
                    )
                  }
                </>
              )
            }
          </Grid>
        </Grid>

        <ReturnButton />
      </ContentWrapper>
    </div>
  );

}

export default MyProfile;
