import React from "react";
import { useTranslation } from 'react-i18next';

import RecruiterContactForm from "./ContactRecruiterForm";
import { ModalFormWrapper } from "./ModalFormWrapper";


const ContactRecruiterModal = ({ open, handleClose, candidate, campaignId, authenticated }) => {

    const [t] = useTranslation(['newTranslations']);

    return (
        <ModalFormWrapper
            formTitle={ t("newTranslations:contactRecruiterFormTitle") }
            formTooltip={ t("newTranslations:contactRecruiterFormTooltip") }
            handleOpen={open}
            handleClose={handleClose}
            maxWidth={"sm"}
        >
            <RecruiterContactForm 
                candidate={candidate}
                campaignId={campaignId}
                authenticated={authenticated}
            />
        </ModalFormWrapper>
    );

}

export default ContactRecruiterModal;