import React from 'react';
import { useSelector } from 'react-redux';

import { Grid, Card, CardContent } from '@material-ui/core';

import CampaignKeyCompetencyCard from './../../Campaigns/CampaignDetail/CampaignKeyCompetencyQuestions/CampaignKeyCompetencyCard';

import CampaignVideoUpload from './CampaignVideoUpload';
import IntroductionInformation from './IntroductionInformation';
import TalentAlertInformation from './TalentAlertInformation';
import TalentAlertTermsAndConditions from './TermsAndConditions/TalentAlertTermsAndConditions';
import TalentAlertStatus from './TalentAlertStatus';
import TalentAlertCampaignDocuments from './TalentAlertCampaignDocuments';

const TalentAlertDetails = ({ ...rest }) => {

  const talentAlert = useSelector(state => state.talentAlert);

  const { consultancyId } = useSelector((state) => state.session);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TalentAlertInformation guestView={rest?.guestView} />
          </Grid>
          
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <TalentAlertStatus guestView={rest?.guestView} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <CampaignKeyCompetencyCard guestView={rest?.guestView} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} lg={6}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <IntroductionInformation 
              campaignId={talentAlert.id}
              consultancyId={consultancyId}
              introductionDetails={talentAlert.introduction}
              headerImg={talentAlert.headerImg}
              campaignLogoImg={ talentAlert.campaignLogoImg } />  
          </Grid>
          
          <Grid item xs={12}>
            <CampaignVideoUpload 
              video={talentAlert.campaignVideo}
              isVideoProcessing={talentAlert.isProcessing}
              campaignId={talentAlert.id} />
          </Grid>
          
          <Grid item xs={12}>
            <TalentAlertTermsAndConditions guestView={rest?.guestView} />
          </Grid>
        </Grid>
      </Grid>
      
      <Grid item xs={12} lg={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <TalentAlertCampaignDocuments
                  campaignRequiredDocuments={talentAlert.campaignRequiredDocuments}
                  campaignModules={talentAlert.campaignModules} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

};

export default TalentAlertDetails;
