import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import parse from 'html-react-parser';

import { AppBar, Card, CardContent, Grid, Typography } from '@material-ui/core';
import DetailsIcon from '@material-ui/icons/Details';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import { makeStyles } from '@material-ui/styles';

import * as contactApi from './../../api/contactApi';

import * as campaignActions from './../../actions/campaignActions';

import CampaignDocumentsCard from './../Campaigns/CampaignDetail/CampaignDocuments/CampaignDocumentsCard';
import CalendarView from './../../components/calendar/CalendarView';
import CandidatesMap from './../Campaigns/CampaignDetail/CandidatesMap';
import CampaignMcQuaig from './../Campaigns/CampaignDetail/CampaignMcQuaig';
import ContactCandidateStageView from './ContactCandidateStageView';
import { ContentWrapper } from './../../components/LayoutComponents/ContentWrapper';
import CustomTabs from './../../components/LayoutComponents/CustomTabs';
import { BlockHeader } from './../../components/LayoutComponents/BlockHeader';
import { PageTitle } from './../../components/LayoutComponents/PageTitle';
import { TabPanel } from './../../components/TabPanel';

import { useCampaignOrVacancyTranslationPrefix } from './../../utils/useSubscriptionPrefix';

import { INFORMATION_BLOCKS_ID, CANDIDATES_MAP_ID } from './../../constants/moduleIds';
import { CANDIDATE, CONTACT } from './../../constants/roles';
import { TA_PRO } from './../../constants/subscriptionPlansIds';

const useStyles = makeStyles(() => ({
  wrapper: {
    overflow: 'hidden',
    paddingBottom: '20px'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  gridRow: {
    marginTop: '20px'
  },
  appBar: {
    boxShadow: 'none',
    backgroundColor: 'transparent'
  }
}));

const ContactCampaignDetail = ({ campaignId, contactView }) => {

  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();

  const classes = useStyles();

  const history = useHistory();

  const [t, i18n] = useTranslation(['campaigns', 'common', 'snackbar', 'vacancies']);

  const dispatch = useDispatch();

  const [breadcrumbs, setBreadCrumbs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const { consultancyId, consultancyLogo, consultancySubscriptionPlanId } = useSelector(state => state.session);
  const campaign = useSelector(state => state.campaign);
  const userRole = useSelector(state => state.session.roleType);
  let { campaignLogo, campaignModuleIds } = useSelector(state => state.campaign);
  const sessionLanguage = useSelector((state) => state.session.language);

  const canUpdateCalendar = userRole === CANDIDATE || (userRole === CONTACT && campaign.isPrimaryContact);

  const tabs = [{ value: 0, label: t(`${campaignsPrefix}:campaignDetailLabel`), icon: <DetailsIcon /> }];

  if (!contactView?.contactView && canUpdateCalendar) {
    tabs.push({ value: 1, label: t(`${campaignsPrefix}:bookingScheduler`), icon: <InsertInvitationIcon /> });
  }

  useEffect(() => {
    if (contactView?.contactView) return;

    getContactCampaignById(campaignId);
    i18n.changeLanguage(sessionLanguage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBreadCrumbs([
      {
        title: consultancySubscriptionPlanId === TA_PRO ? 'Vacancies' : 'Campaigns',
        link: ''
      },
      {
        title: campaign?.campaignTitle,
        link: consultancySubscriptionPlanId === TA_PRO ? `/vacancies/${campaign?.id}` : `/campaigns/${campaign?.id}`,
      },
    ]);

    i18n.changeLanguage(sessionLanguage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  const getContactCampaignById = (campaignId) => {
    setIsLoading(true);

    contactApi.getContactCampaignById(campaignId, consultancyId)
      .then((response) => {
        dispatch({
          type: campaignActions.UPDATE_CAMPAIGN,
          campaign: response.data,
        });
      })
      .catch((error) => {
        history.push('/dashboard');
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (value) => {
    setCurrentTab(value);
  };

  return (
    <div className={ classes.wrapper } >
      <PageTitle
        breadcrumbs={ breadcrumbs }
        btnLabel={ contactView?.contactView ? t("campaigns:cancelClientView") : null }
        btnFunc={ contactView?.toggleContactView } />

      <ContentWrapper loading={ isLoading }>
        <Grid container spacing={ 3 }>
          <Grid item xs={ 12 } className={ classes.gridRow }>
            <AppBar position="static" className={ classes.appBar }>
              <CustomTabs tabs={ tabs } onChange={ handleChange } tabClassName={ classes.tab } />
            </AppBar>
          </Grid>

          <Grid item xs={12} className={classes.gridRow}>
            <TabPanel value={currentTab} index={0}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          <Grid container spacing={3}>
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                              <img
                                style={{ maxHeight: "100px", maxWidth: "100%" }}
                                src={campaignLogo || consultancyLogo}
                                alt="" />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>

                    {
                      campaignModuleIds?.includes(CANDIDATES_MAP_ID) && (
                        <Grid item xs={12}>
                          <Card>
                            <CardContent
                              style={{
                                height: "300px",
                                width: "100%",
                                position: "relative",
                                padding: "0",
                              }} >
                              <CandidatesMap contactView={contactView?.contactView} />
                            </CardContent>
                          </Card>
                        </Grid>
                      )
                    }

                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          <CampaignDocumentsCard
                            documents={campaign.campaignDocuments}
                            contactView={true}
                            candidateView={true}
                            guestView={true} />
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <ContactCandidateStageView
                        contactView={contactView?.contactView}
                      />
                    </Grid>

                    {
                      campaign.bgRequirements && (
                        <Grid item xs={12}>
                          <Card>
                            <BlockHeader
                              card
                              padding
                              headerTooltip={t("tooltips:contactback")}
                              header={t("campaigns:backgroundInformation")} />

                            <CardContent>
                              <div class="ql-editor">
                                {parse(campaign.bgRequirements || "")}
                              </div>
                            </CardContent>
                          </Card>
                        </Grid>
                      )
                    }

                    {
                      campaignModuleIds?.includes(INFORMATION_BLOCKS_ID) && campaign.campaignInformationBlocks.length > 0 && (
                        <Grid item xs={12}>
                          {
                          campaign.campaignInformationBlocks
                            .filter(
                              (informationBlock) =>
                                informationBlock.visibility.includes("contacts") //this is required for client preview mode
                            )
                            .map((informationBlock) => (
                              <Card style={{ marginBottom: "20px" }}>
                                <CardContent>
                                  <Typography variant="h3">
                                    {informationBlock.title}
                                  </Typography>

                                  <div class="ql-editor">
                                    {parse(informationBlock.content || "")}
                                  </div>
                                </CardContent>
                              </Card>
                            ))
                          }
                        </Grid>
                      )
                    }

                    {
                      campaign.campaignMcQuaig !== "disabled" && (
                        <Grid item xs={12}>
                          <CampaignMcQuaig guestView={true} />
                        </Grid>
                      )
                    }
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={currentTab} index={1}>
              <CalendarView
                contactView={true}
                calendarEntries={
                  campaign.calendarEntries ? campaign.calendarEntries : []
                }
                canUpdateCalendar={canUpdateCalendar} />
            </TabPanel>
          </Grid>
        </Grid>
      </ContentWrapper>
    </div>
  );

}

export default ContactCampaignDetail;
