import axios from '../utils/axios';

//requests both the system admin and admin can call
export const getAllConsultancies = () => {
  return axios.get('/SystemAdmins/getConsultancyIdsAndNames');
};

export const createNewConsultancy = (newConsultancyData) => {
  return axios.post('/SystemAdmins/createConsultancy', newConsultancyData);
};

export const getConsultancyUsers = (consultancyId) => {
  return axios.get('/Consultancies/getConsultancyUsers', {
    params: {
      consultancyId,
    },
  });
};

export const exportToCsv = (dateFrom, dateTo) => {
  return axios.get('/SystemAdmins/exportToCsv', {
    params: {
      dateFrom,
      dateTo
    }
  });
}

export const updateConsultancyDetails = ({
  consultancyName,
  systemName,
  telephone,
  addressLine1,
  addressLine2,
  city,
  postalCode,
  country,
  consultancyId,
  language,
  campaignCandidateTCs,
  campaignContactTCs,
  talentAlertCandidateTCs,
  talentAlertContactTCs,
  consultancySubscriptionPlanId
}) => {
  return axios.post('/Consultancies/updateConsultancy', {
    consultancyName,
    systemName,
    telephone,
    addressLine1,
    addressLine2,
    city,
    postalCode,
    consultancyId,
    countryId: country.id,
    localeId: language,
    campaignCandidateTCs,
    campaignContactTCs,
    talentAlertCandidateTCs,
    talentAlertContactTCs,
    consultancySubscriptionPlanId
  });
};

export const createConsultancyUser = (
  consultancyUserDetails,
  consultancyId
) => {
  return axios.post(
    '/Consultancies/createConsultancyUser',
    consultancyUserDetails,
    {
      params: {
        consultancyId,
      },
    }
  );
};

export const getSystemAdmins = () => {
  return axios.get('/SystemAdmins/getSystemAdmins');
};

export const createSystemAdmin = (newSystemAdminDetails) => {
  return axios.post('/SystemAdmins/createSystemAdmin', newSystemAdminDetails);
};

export const toggleUserEnabled = ({ enabled, id }) => {
  return axios.post('/BaseUsers/toggleUserEnabled', {
    enabled,
    id,
  });
};

export const toggleConsultancyUserEnabled = (
  { enabled, id },
  consultancyId
) => {
  return axios.post('/BaseUsers/toggleConsultancyUserEnabled', {
    enabled,
    id,
    consultancyId,
  });
};

export const getDefaultDocuments = () => {
  return Promise.all([
    axios.get('/DefaultCandidateDocuments/getSystemDefaults').catch((error) => {
      console.error(error);
    }),
    axios.get('/DefaultCampaignDocuments/getSystemDefaults').catch((error) => {
      console.error(error);
    }),
  ]);
};

export const saveDefaultCandidateDocument = (document) => {
  return axios.post('/DefaultCandidateDocuments/saveSystemDefault', {
    document,
  });
};

export const saveDefaultCampaignDocument = (document) => {
  return axios.post('/DefaultCampaignDocuments/saveSystemDefault', {
    document,
  });
};

export const removeDefaultCandidateDocument = (id) => {
  return axios.delete('/DefaultCandidateDocuments/deleteSystemDefault', {
    params: {
      id,
    },
  });
};

export const removeDefaultCampaignDocument = (id) => {
  return axios.delete('/DefaultCampaignDocuments/deleteSystemDefault', {
    params: {
      id,
    },
  });
};

export const getAvailableModules = (isEnabled) => {
  return axios.get('/AvailableModules/getAvailableModules', {
    params: { isEnabled },
  });
};

export const toggleModuleIsActive = (id, isEnabled) => {
  return axios.put('/AvailableModules/toggleModuleState', {
    id,
    isEnabled,
  });
};

export const createModuleForConsultancy = (
  consultancyId,
  moduleId,
  moduleName
) => {
  return axios.post('/ConsultancyModules/createModuleForConsultancy', {
    consultancyId,
    moduleId,
    moduleName,
  });
};

export const deleteModuleForConsultancy = (id) => {
  return axios.delete('/ConsultancyModules/deleteModuleForConsultancy', {
    params: {
      id,
    },
  });
};

export const getDefaultWelcomeMessage = (roleType) => {
  return axios.get('/DefaultWelcomeMessages/getSystemDefaults', {
    params: { roleType },
  });
};

export const saveDefaultWelcomeMessage = (message) => {
  return axios.post('/DefaultWelcomeMessages/saveSystemDefault', {
    message,
  });
};

export const removeDefaultWelcomeMessage = (id) => {
  return axios.delete('/DefaultWelcomeMessages/deleteSystemDefault', {
    params: {
      id,
    },
  });
};

export const getGlobalEmailTemplates = () => {
  return axios.get('/SystemAdmins/getGlobalEmailTemplates');
};

export const updateGlobalEmailTemplate = (emailContent) => {
  return axios.post('/SystemAdmins/updateGlobalEmailTemplate', emailContent);
};

export const getAllUsers = (filters) => {
  return axios.get('/SystemAdmins/getAllUsers', {
    params: {
      filters,
    },
  });
};

export const getConsultancies = () => {
  return axios.get('/SystemAdmins/getConsultancies');
};

export const disableAccounts = (userIds) => {
  return axios.post('/SystemAdmins/disableAccounts', userIds);
};
export const enableAccounts = (userIds) => {
  return axios.post('/SystemAdmins/enableAccounts', userIds);
};
export const resendAccountActivationEmails = (userIds) => {
  return axios.post('/SystemAdmins/resendAccountActivationEmails', userIds);
};

export const getAllCampaigns = (filters) => {
  return axios.get('/SystemAdmins/getAllCampaigns', {
    params: {
      filters,
    },
  });
};

export const getAllEmployers = (filters) => {
  return axios.get('/SystemAdmins/getAllEmployers', {
    params: {
      filters,
    },
  });
};

export const toggleCandidateUserStatus = (consultancyId, userId) => {
  return axios.put('/BaseUsers/toggleCandidateUserStatus', null, {
    params: {
      consultancyId,
      userId,
    },
  });
};

export const createEmployer = (employerData) => {
  return axios.post('/SystemAdmins/createEmployer', employerData);
};

export const toggleContactUserStatus = (consultancyId, contactId) => {
  return axios.put('/BaseUsers/toggleContactUserStatus', null, {
    params: {
      consultancyId,
      contactId,
    },
  });
};

export const updateUserEmail = (email, consultancyId) => {
  return axios.put('/BaseUsers/updateUserEmail', null, {
    params: {
      email,
      consultancyId,
    },
  });
};

export const updateUserPassword = (formData, consultancyId) => {
  return axios.post('/BaseUsers/updatePassword', formData, {
    params: {
      consultancyId,
    },
  });
};

export const disableOrEnableConsultancy = (consultancyId) => {
  return axios.put('/SystemAdmins/disableOrEnableConsultancy', null, {
    params: {
      consultancyId,
    },
  });
};

export const getConsultancySubscriptionPlans = () => {
  return axios.get('/ConsultancySubscriptionPlans/getSubscriptionPlans');
};

export const changeUserPassword = (userId, consultancyId, password) => {
  return axios.post(
    '/Admins/changeUserPassword',
    { password },
    {
      params: {
        userId,
        consultancyId
      }
    }
  );
};

export const isUserActivated = (userId) => {
  return axios.get(
    '/Admins/isUserActivated',
    {
      params: { userId }
    }
  );
};
