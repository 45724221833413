import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Grid, Button, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LocationOn from '@material-ui/icons/LocationOn';

import KeyFacts from './../TalentAlertContactCandidateProfile/KeyFacts';

const styles = {
  videoPreviewWrapper: {
    width: '100%',
    height: '400px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    boxShadow: "inset 2000px 0 0 0 rgba(0, 0, 0, 0.5)"
  },
  playButton: {
    width: '100%',
    height: '360px',
    left: '0%',
    position: 'absolute',
    right: '0%',
    top: '-3%',
    bottom: '0%',
    margin: 'auto',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    '&:hover': {
      opacity: '0.7',
      cursor: 'pointer'
    },
    color: '#ffffff',
    opacity: '1'
  },
  videoPreview: {
    height: '360px',
    zIndex: '1'
  }
};

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: '#ffffff',
    boxShadow: 'rgba(0, 0, 0, 0.30) 0px 5px 15px',
    marginBottom: '30px',
    borderRadius: '3px'
  },
  videoPreviewWrapper: styles.videoPreviewWrapper,
  videoPreviewWrapperMobile: {
    ...styles.videoPreviewWrapper,
    height: '240px'
  },
  imgBackground: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    bottom: '0px',
    right: '0px',
    overflow: 'hidden',
    filter: 'blur(10px)'
  },
  playButton: styles.playButton,
  playButtonMobile: {
    ...styles.playButton,
    height: '220px'
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  videoPreview: styles.videoPreview,
  videoPreviewMobile: {
    ...styles.videoPreview,
    height: '220px'
  },
  candidate: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  name: {
    fontSize: '1.3rem'
  },
  typographyReferenceNumber: {
    opacity: '0.7'
  },
  nameBox: {
    display: 'inline',
    width: '55%',
    padding: '20px'
  },
  locationBox: {
    fontSize: '1.3rem',
    display: 'inline',
    width: '45%',
    padding: '20px'
  },
  button: {
    display: 'block',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8'
    },
    maxHeight: '40px',
    margin: '10px auto'
  }
}));

const SingleCandidate = ({ candidateData, campaignId, loggedIn, preview }) => {

  const classes = useStyles();

  const history = useHistory();

  const [t] = useTranslation(['newTranslations']);

  const isMobile = useMediaQuery('(max-width:767px');

  const candidateId = candidateData.id;

  const goToCandidate = () => {
    if (loggedIn || preview) {
      history.push({
        pathname: `/dashboard/talent-alerts/${campaignId}/candidates/${candidateId}`,
        state: {
          previousPath: history.location.pathname
        }
      });

      return;
    }

    history.push(`/talent-alert/${campaignId}/${candidateId}`);
  };

  const renderCandidateDetails = () => {
    if(loggedIn){
      return (
          <>
            <Typography className={classes.name}>
              { candidateData?.firstName } { candidateData?.lastName }
            </Typography>
            <hr />
            <Typography>
              { candidateData?.jobTitle }
            </Typography>
          </>
      );
    } else {
      return (
        <>
          <Typography className={classes.name}>
            { candidateData?.jobTitle }
          </Typography>

          {
            candidateData?.referenceNumber && (
              <>
                <hr />
                <Typography className={ classes.typographyReferenceNumber }>
                  #{ candidateData.referenceNumber }
                </Typography>
              </>
            )
          }
        </>
      );
    }
  };

  return (
    <Grid container xs={10} className={`${classes.paper} + ${classes.candidate}`}>
      <Grid xs={12} md={6} className={classes.wrapper} onClick={goToCandidate}>
        <div
          className={ isMobile ? classes.videoPreviewWrapperMobile : classes.videoPreviewWrapper } >
          {
            !candidateData?.isProfileVideoGifAvailable && (
              <>
                <img src="https://res.cloudinary.com/dhiwlvpg7/image/upload/v1649248807/home/000_-_237_ht3qki.png" alt="" className={ isMobile ? classes.playButtonMobile : classes.playButton } />
              </>
            )
          }

          <div className={ classes.imgBackground } style={{ backgroundImage: `url(${preview ? candidateData?.profilePicture : candidateData?.avatar})` }}></div>
          <img src={preview ? candidateData?.profilePicture : candidateData?.avatar} alt="" className={ isMobile ? classes.videoPreviewMobile : classes.videoPreview } />
        </div>
      </Grid>

      <Grid container item xs={12} md={6}>
        <div className={classes.nameBox}>
          { renderCandidateDetails() }
        </div>

        <div className={classes.locationBox}>
          <LocationOn />

          {
            candidateData?.address?.city ?
            `${candidateData.address.city}, ${candidateData.address.country?.name}` :
            candidateData?.address?.country?.name
          }
        </div>

        <KeyFacts keyFacts={candidateData?.keyFacts} />

        <Button className={classes.button} onClick={goToCandidate}>{t("newTranslations:viewCandidate")}</Button>
      </Grid>
    </Grid>
  )

};

export default SingleCandidate;
