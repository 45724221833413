import React from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import Swal from "sweetalert2";
import {
	useTheme,
	Avatar,
	Grid,
	Card,
	CardContent,
	TextField,
	Button,
	Typography,
	CircularProgress,
} from "@material-ui/core";
import CustomFileDropzone from "src/components/CustomFileDropzone";
import CountrySelect from "src/components/CountrySelect";
import { useTranslation } from "react-i18next";
import * as ContactApi from "src/api/contactApi";
import { useSnackbar } from "notistack";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import { CardButton } from "src/components/LayoutComponents/CardButton";

import ChangeEmail from './../../components/ChangeEmail';
import ChangePassword from './../../components/ChangePassword';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	fields: {
		margin: theme.spacing(-1),
		display: "flex",
		flexWrap: "wrap",
		"& > *": {
			flexGrow: 1,
			margin: theme.spacing(1),
		},
	},
	submitButton: {
		marginTop: theme.spacing(2),
		width: "100%",
	},
	gridRow: {
		marginTop: "20px",
	},
	consultancyLogo: {
		height: "50px",
		width: "auto",
	},
	inputRow: {
		alignItems: "center",
	},
	headerRow: {
		padding: "0px 25px",
	},
	pageContainer: {
		margin: "10px",
	},
	loadingIndicator: {
		display: "flex",
		justifyContent: "center",
	},
}));

function ContactProfile({
	consultancyId,
	contactId,
	employer,
	profile,
	profilePicture,
	saveDetails,
	formState,
	toggleAccount,
	enabled,
	handleChange,
	hasError,
	handleCountryChange,
}) {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();
	const roleType = useSelector((state) => {
		return state.session.roleType;
	});

	const isUserAdmin =
		roleType?.toLowerCase() === "admin" ||
		roleType?.toLowerCase() === "coreadmin" ||
		roleType?.toLowerCase() === "sysadmin";

	const [t] = useTranslation(["common", "validation", "snackbar", "changePassword"]);

	const goToEmployerDetails = (event, employerId) => {
		event.preventDefault();
		history.push(`/dashboard/employers/${employerId}`);
	};

	const handleUploadContactImage = (value) => {
		return ContactApi.uploadContactImage(consultancyId, contactId, value)
			.then((response) => {
				enqueueSnackbar(t("snackbar:contactPictureUploadSuccess"), {
					variant: "success",
				});
			})
			.catch((error) => {
				enqueueSnackbar(t("snackbar:contactPictureUploadFailure"), {
					variant: "error",
				});
			});
	};

	const handleDelete = () => {
		Swal.fire({
			title: `${t("common:areYouSure")}`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: `${t("common:yes")}`,
			cancelButtonText: `${t("common:no")}`,
			confirmButtonColor: theme.palette.warning?.main
			  ? theme.palette.warning.main
			  : theme.palette.primary.main,
		})
		.then((resp) => {
			if (resp.isConfirmed) {
			  	return ContactApi
					.deleteContact(contactId, consultancyId)
					.then(() => {
						history.push("/dashboard/employers");
						enqueueSnackbar(t("Contact was deleted"), {
							variant: "success",
						});
					})
					.catch(() => {
						enqueueSnackbar(t("Failure delete"), {
							variant: "error",
						});
					});
			}
		});
	}

	const changePassword = (password) => {
		return ContactApi.changePassword(contactId, consultancyId, password)
			.then(() => {
				enqueueSnackbar(t('changePassword:notificationSuccessPasswordChange'), { variant: 'success' });
			})
			.catch((err) => {
				console.log(err);
				enqueueSnackbar(t('changePassword:notificationErrorPasswordChange'), { variant: 'error' });
			});
	};

	const changeEmail = (email) => {
		return ContactApi.changeEmail(contactId, email)
			.then(() => {
				enqueueSnackbar(t('changeEmail:notificationSuccessEmailChange'), { variant: 'success' });
			})
			.catch((err) => {
				console.log(err);
				enqueueSnackbar(t('changeEmail:notificationErrorEmailChange'), { variant: 'error' });
			});
	};

	if (!profile) {
		return (
			<Grid item xs={12} className={classes.loadingIndicator}>
				<CircularProgress />
			</Grid>
		);
	}

	return (
		<Grid container spacing={6} className={classes.pageContainer}>
			<Grid item xs={12} lg={3}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Card>
							<CardContent>
								<Grid container spacing={3}>
									<Grid item xs={12} style={{ textAlign: "left" }}>
										<BlockHeader
											reduceMargin
											header={t("common:manageAvatar")}
										/>
										<CustomFileDropzone
											type="single"
											format="image"
											uploadFunction={handleUploadContactImage}
											placeHolderImage={
												profilePicture || employer?.employerLogo
											}
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
					{isUserAdmin && (
						<Grid item xs={12}>
							<Card>
								<CardContent>
									<BlockHeader
										defeatMargin
										header={t("labels:manageAccountStatus")}
									/>
									<Grid item xs={12}>
										<Button
											className={classes.submitButton}
											color="primary"
											size="large"
											variant="outlined"
											type="submit"
											onClick={toggleAccount}
										>
											{enabled
												? `${t("common:disableAccount")}`
												: `${t("common:enableAccount")}`}
										</Button>
										<Button
											className={classes.submitButton}
											color='secondary'
											size='large'
											type='submit'
											variant='contained'
											onClick={handleDelete}
											>
											{t("delete account")}
										</Button>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
					)}

					{employer ? (
						<Grid item xs={12}>
							<Card>
								<CardContent>
									<Grid container align="center" spacing={1}>
										<Grid item xs={12}>
											<BlockHeader card header={t("common:employerDetails")} />
										</Grid>
										<Grid item xs={12}>
											<Avatar
												src={employer.employerLogo}
												variant="circle"
											></Avatar>
										</Grid>
										<Grid item xs={12}>
											<Typography variant="h4">
												{employer.employerName}
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Button
												onClick={(e) => goToEmployerDetails(e, employer.id)}
												className={classes.submitButton}
												color="primary"
												size="large"
												variant="outlined"
											>
												{t("common:view")}
											</Button>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
					) : null}
				</Grid>

				<ChangeEmail changeEmail={ changeEmail } consultancyId={ consultancyId } contactId={ contactId } />

				<ChangePassword changePassword={ changePassword } contactId={ contactId } />
			</Grid>
			<Grid item xs={12} lg={9}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Card>
							<CardContent>
								<Grid container spacing={3}>
									<Grid item xs={12}>
										<BlockHeader card header={t("labels:manageDetails")} />
										<Grid container spacing={3} className={classes.inputRow}>
											<Grid item xs={12} md={3}>
												<Typography variant="h5">
													{t(`common:salutationLabel`)}
												</Typography>
											</Grid>
											<Grid item xs={12} md={9}>
												<TextField
													fullWidth
													name="salutation"
													variant="outlined"
													onChange={handleChange}
													error={hasError("salutation")}
													value={formState.values.salutation || ""}
													helperText={
														hasError("salutation")
															? t(
																	`validation:${formState.errors.salutation[0]}`
															  )
															: null
													}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={3} className={classes.inputRow}>
											<Grid item xs={12} md={3}>
												<Typography variant="h5">
													{t(`common:firstNameLabel`)}
												</Typography>
											</Grid>
											<Grid item xs={12} md={9}>
												<TextField
													fullWidth
													name="firstName"
													variant="outlined"
													onChange={handleChange}
													error={hasError("firstName")}
													value={formState.values.firstName || ""}
													helperText={
														hasError("firstName")
															? t(`validation:${formState.errors.firstName[0]}`)
															: null
													}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={3} className={classes.inputRow}>
											<Grid item xs={12} md={3}>
												<Typography variant="h5">
													{t(`common:lastNameLabel`)}
												</Typography>
											</Grid>
											<Grid item xs={12} md={9}>
												<TextField
													fullWidth
													name="lastName"
													variant="outlined"
													onChange={handleChange}
													error={hasError("lastName")}
													value={formState.values.lastName || ""}
													helperText={
														hasError("lastName")
															? t(`validation:${formState.errors.lastName[0]}`)
															: null
													}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={3} className={classes.inputRow}>
											<Grid item xs={12} md={3}>
												<Typography variant="h5">
													{t(`common:jobTitleLabel`)}
												</Typography>
											</Grid>
											<Grid item xs={12} md={9}>
												<TextField
													fullWidth
													name="jobTitle"
													variant="outlined"
													onChange={handleChange}
													error={hasError("jobTitle")}
													value={formState.values.jobTitle || ""}
													helperText={
														hasError("lastName")
															? t(`validation:${formState.errors.lastName[0]}`)
															: null
													}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={3} className={classes.inputRow}>
											<Grid item xs={12} md={3}>
												<Typography variant="h5">
													{t(`common:telephoneLabel`)}
												</Typography>
											</Grid>
											<Grid item xs={12} md={9}>
												<TextField
													fullWidth
													name="telephone"
													variant="outlined"
													onChange={handleChange}
													error={hasError("telephone")}
													value={formState.values.telephone || ""}
													helperText={
														hasError("telephone")
															? t(`validation:${formState.errors.telephone[0]}`)
															: null
													}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={3} className={classes.inputRow}>
											<Grid item xs={12} md={3}>
												<Typography variant="h5">
													{t(`common:addressLabel`)} 1
												</Typography>
											</Grid>
											<Grid item xs={12} md={9}>
												<TextField
													fullWidth
													name="addressLine1"
													variant="outlined"
													onChange={handleChange}
													error={hasError("addressLine1")}
													value={formState.values.addressLine1 || ""}
													helperText={
														hasError("addressLine1")
															? t(
																	`validation:${formState.errors.addressLine1[0]}`
															  )
															: null
													}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={3} className={classes.inputRow}>
											<Grid item xs={12} md={3}>
												<Typography variant="h5">
													{t(`common:addressLabel`)} 2
												</Typography>
											</Grid>
											<Grid item xs={12} md={9}>
												<TextField
													fullWidth
													name="addressLine2"
													variant="outlined"
													onChange={handleChange}
													error={hasError("addressLine2")}
													value={formState.values.addressLine2 || ""}
													helperText={
														hasError("addressLine2")
															? t(
																	`validation:${formState.errors.addressLine2[0]}`
															  )
															: null
													}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={3} className={classes.inputRow}>
											<Grid item xs={12} md={3}>
												<Typography variant="h5">{t(`common:City`)}</Typography>
											</Grid>
											<Grid item xs={12} md={4}>
												<TextField
													fullWidth
													name="city"
													variant="outlined"
													onChange={handleChange}
													error={hasError("city")}
													value={formState.values.city || ""}
													helperText={
														hasError("city")
															? t(`validation:${formState.errors.city[0]}`)
															: null
													}
												/>
											</Grid>
											<Grid item xs={12} md={2}>
												<Typography variant="h5">
													{t(`common:postcodeLabel`)}
												</Typography>
											</Grid>
											<Grid item xs={12} md={3}>
												<TextField
													fullWidth
													name="postalCode"
													variant="outlined"
													onChange={handleChange}
													error={hasError("postalCode")}
													value={formState.values.postalCode || ""}
													helperText={
														hasError("postalCode")
															? t(
																	`validation:${formState.errors.postalCode[0]}`
															  )
															: null
													}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={3} className={classes.inputRow}>
											<Grid item xs={12} md={3}>
												<Typography variant="h5">
													{t(`common:countryLabel`)}
												</Typography>
											</Grid>
											<Grid item xs={12} md={9}>
												<CountrySelect
													handleCountryChange={handleCountryChange}
													value={formState.values.country}
												/>
											</Grid>
										</Grid>
										<CardButton
											save
											btnFunc={saveDetails}
											btnVariant="outlined"
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default ContactProfile;