import React from "react";
import { makeStyles } from "@material-ui/styles";
import { CircularProgress, Container, Grid, Slide } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: "0% 5%",
  },
  removeGutters: {
    padding: 0,
  },
  header: {
    marginBottom: 25,
  },
  gridRow: {
    marginTop: "20px",
  },
}));

export const ContentWrapper = ({
  children,
  loading,
  removeGutters,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Container
      className={removeGutters ? classes.removeGutters : classes.container}
      maxWidth="xl"
    >
      {loading ? (
        <div
          style={{
            textAlign: "center",
            margin: "25vh 0",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Slide in={!loading} direction="left">
          <Grid container spacing={3}>
            {children ? (
              children.length > 0 ? (
                children.map((child) => <>{child}</>)
              ) : (
                <>{children}</>
              )
            ) : null}
          </Grid>
        </Slide>
      )}
    </Container>
  );
};
