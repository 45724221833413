import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Card, CardContent, FormControl, Grid, IconButton, InputAdornment, OutlinedInput, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/styles';

import * as candidateApi from './../../api/candidateApi';
import * as contactApi from './../../api/contactApi';
import * as systemAdminApi from './../../api/SystemAdminApi';

import { BlockHeader } from '../LayoutComponents/BlockHeader';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: '16px',
        paddingBottom: '24px',
        marginTop: '16px'
    },
    formControl: {
        width: '100%'
    },
    typography: {
        marginBottom: '4px'
    },
    button: {
        marginTop: '16px'
    },
    activationStatusGrid: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        marginTop: '32px'
    },
    activationStatusTypographyActive: {
        fontWeight: '800',
        color: theme.palette.success.main
    },
    activationStatusTypographyInactive: {
        fontWeight: '800',
        color: theme.palette.error.main
    },
    activationStatusCheckIcon: {
        color: theme.palette.success.main
    },
    activationStatusCloseIcon: {
        color: theme.palette.error.main
    }
}));

const ChangePassword = ({ changePassword, userId, contactId, candidateId }) => {

    const [t] = useTranslation(['changePassword']);
    const classes = useStyles();

    const [password, setPassword] = useState('');
    const [doShowPassword, setDoShowPassword] = useState(false);
    const [isUserActivated, setIsUserActivated] = useState(false);

    useEffect(() => {
        let request;

        if (candidateId) {
            request = candidateApi.isCandidateActivated(candidateId);
        } else if (contactId) {
            request = contactApi.isContactActivated(contactId);
        } else {
            request = systemAdminApi.isUserActivated(userId);
        }

        request
            .then((response) => {
                setIsUserActivated(response.data);
            })
            .catch((err) => {
                console.log(err);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event) => {
        setPassword(event.target.value);
    };

    const showHidePassword = () => {
        setDoShowPassword((prevState) => !prevState);
    };

    const generatePassword = () => {
        const generatedPassword = Math.random().toString(36).slice(-8);
        setPassword(generatedPassword);
    };

    const changePasswordHandler = () => {
        changePassword(password)
            .then(() => {
                setPassword('');
                setIsUserActivated(true);
            });
    };

    return (
        <>
            <Card className={ classes.card }>
                <BlockHeader card header={ t("changePassword:header") } />

                <CardContent>
                    <Grid container>
                        <FormControl variant="outlined" className={ classes.formControl }>
                            <Typography variant="h5" className={ classes.typography }>
                                { t("changePassword:passwordLabel") }
                            </Typography>

                            <OutlinedInput
                                value={ password }
                                onChange={ handleChange }
                                type={ doShowPassword ? "text" : "password" }
                                label={ t("changePassword:passwordLabel") }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" edge="end" onClick={ showHidePassword } >
                                            { doShowPassword ? <VisibilityOffIcon /> : <VisibilityIcon /> }
                                        </IconButton>
                                    </InputAdornment>
                                } />
                        </FormControl>
                    </Grid>

                    <Button
                        color="primary"
                        fullWidth
                        variant="outlined"
                        size="large"
                        className={ classes.button }
                        onClick={ generatePassword } >
                        { t("changePassword:generatePasswordBtn") }
                    </Button>

                    <Button
                        color="primary"
                        fullWidth
                        variant="contained"
                        size="large"
                        className={ classes.button }
                        onClick={ changePasswordHandler } >
                        { t("changePassword:changePasswordBtn") }
                    </Button>

                    <Grid className={ classes.activationStatusGrid }>
                        <Typography className={ isUserActivated ? classes.activationStatusTypographyActive : classes.activationStatusTypographyInactive }>
                            { t("changePassword:changePasswordUserActivationStatusLabel") }
                        </Typography>

                        {
                            isUserActivated ?
                            <CheckIcon className={ classes.activationStatusCheckIcon } /> :
                            <CloseIcon className={ classes.activationStatusCloseIcon } />
                        }
                    </Grid>
                </CardContent>
            </Card>
        </>
    );

};

export default ChangePassword;
