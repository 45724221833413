import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import VideoRecorder from 'react-video-recorder';

import { Box, Button, CircularProgress, Container, IconButton, Modal, Typography, useMediaQuery } from '@material-ui/core';
import { CameraAltOutlined, Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';

import * as candidateApi from './../../../api/candidateApi';
import * as talentAlertApi from './../../../api/talentAlertApi';

import Actions from './../../VideoRecorderCommon/Actions';
import DisconnectedView from './../../VideoRecorderCommon/DisconnectedView';
import LoadingView from './../../VideoRecorderCommon/LoadingView';
import UnsupportedView from './../../VideoRecorderCommon/UnsupportedView';

const styles = {
  container: {
    width: '767px',
    height: '610px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    background: '#fff',
    borderRadius: '5px',
    padding: '0',
    boxShadow: 'rgb(0 0 0 / 70%) 0px 20px 30px',
    transform: 'translate(-50%, -50%)'
  }
};

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: styles.container,
  containerMobile: {
    ...styles.container,
    width: '100%',
    height: '100%'
  },
  iconButton: {
    position: 'absolute',
    top: '12px',
    right: '16px',
    padding: '0'
  },
  close: {
    width: '28px',
    height: '28px'
  },
  header: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    paddingLeft: '10px',
    borderBottom: '1px solid #90A0B3'
  },
  camera: {
    width: '32px',
    height: '32px',
    color: theme.palette.button.main,
    margin: '8px 8px 8px 0px'
  },
  typography: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#5A616A'
  },
  content: {
    height: 'calc(100% - 74px)',
    display: 'block',
    backgroundColor: '#E4EBF1',
    color: '#000000',
    overflowY: 'hidden'
  },
  main: {
    height: 'calc(100% - 70px)',
    textAlign: 'center',
    padding: '18px 15px 10px'
  },
  loader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  videoWrapper: {
    width: '100%',
    height: '80%',
    border: '2px dashed #90A0B3',
    padding: '4px'
  },
  actions: {
    height: '20%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  recordBtn: {
    color: '#fff',
    backgroundColor: '#ab2023',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#771618'
    }
  },
  timer: {
    fontSize: '20px',
    color: theme.palette.button.main,
    marginBottom: '4px'
  },
  stopRecordingBtn: {
    color: '#fff',
    backgroundColor: '#BB4C4F',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#771618'
    }
  },
  uploadBtn: {
    borderRadius: '4px'
  },
  footer: {
    width: '100%',
    height: '70px',
    position: 'absolute',
    bottom: '0',
    left: '0',
    backgroundColor: '#fff',
    padding: '14px 16px',
    borderTop: '1px solid #90A0B3',
    zIndex: '6'
  }
}));

const CloudinaryVideoRecorder = ({ isOpen, onClose, talentAlert }) => {

  const classes = useStyles();

  const isMobile = useMediaQuery('(max-width:767px)');

  const [t] = useTranslation(['cloudinary']);

  const { enqueueSnackbar } = useSnackbar();

  const [props, setProps] = useState();

  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [blob, setBlob] = useState({});
  const [isBlobAvailable, setIsBlobAvailable] = useState(false);

  const [timerSeconds, setTimerSeconds] = useState('00');
  const [timerMinutes, setTimerMinutes] = useState('00');
  const [timerInterval, setTimerInterval] = useState({});

  const campaignId = useSelector(state => state.candidateCampaign.candidateProfile?.campaignId);
  const candidateId = useSelector(state => state.candidateCampaign.candidateProfile?.candidateId);
  const talentAlertId = useSelector(state => state.talentAlert?.id);

  const uploadOptions = talentAlert ? { params: { campaignId: talentAlertId } } : { params: { campaignId, candidateId } };

  const startTimer = () => {
    setTimerInterval(
      setInterval(() => {
        setTimerSeconds(prevState => {
          const seconds = parseInt(prevState);

          if ((seconds + 1) === 60) {
            setTimerMinutes(prevStateMinutes => {
              const minutes = parseInt(prevStateMinutes);
              const newStateMinutes = (minutes + 1) < 10 ? `0${minutes + 1}` : (minutes + 1).toString();
              return newStateMinutes;
            });

            return '00';
          }

          const newState = (seconds + 1) < 10 ? `0${seconds + 1}` : (seconds + 1).toString();
          return newState;
        });
      }, 1000)
    );
  };

  const stopTimer = () => {
    clearInterval(timerInterval);
    setTimerSeconds('00');
    setTimerMinutes('00');
  };

  const renderActions = (actionsProps) => {
    if (!props) {
      setProps(actionsProps);
    }

    return <Actions props={actionsProps} />
  };

  const onRecordingComplete = (blobFile) => {
    setBlob(blobFile);
    setIsBlobAvailable(true);
  };

  const recordHandler = () => {
    props.onStartRecording();
    startTimer();
    setIsRecording(true);
  };

  const reRecordHandler = () => {
    props.onStopReplaying();
    props.onTurnOnCamera();
    setIsBlobAvailable(false);
  };

  const stopRecordHandler = () => {
    props.onStopRecording();
    props.onTurnOffCamera();
    setIsRecording(false);
    stopTimer();
  };

  const uploadVideoHandler = () => {
    setIsLoading(true);

    const fileName = new Date().getTime();
    const formData = new FormData();
    formData.append('file', blob, fileName);

    const promise = talentAlert ? 
      talentAlertApi.uploadCampaignVideo(formData, uploadOptions) :
      candidateApi.uploadProfileVideo(formData, uploadOptions);

    promise
      .then(() => {
        enqueueSnackbar(t('cloudinary:cloudinaryRecordNotificationUploadSuccess'), { variant: 'success' });
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);

        enqueueSnackbar(t('cloudinary:cloudinaryRecordNotificationUploadError'), { variant: 'error' });
        setIsLoading(false);
      });

    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Modal open={isOpen} onClose={onClose} className={classes.modal} >
        <Container className={isMobile ? classes.containerMobile : classes.container} >
          <IconButton className={classes.iconButton} onClick={onClose} >
            <Close className={classes.close} />
          </IconButton>

          <Box className={classes.header} >
            <CameraAltOutlined className={classes.camera} />

            <Typography className={classes.typography} >
              {t("cloudinary:cloudinaryRecordVideoHeader")}
            </Typography>
          </Box>

          <Box className={classes.content} >
            <Box className={classes.main} >
              {
                isLoading ? (
                  <>
                    <Box className={classes.loader} >
                      <CircularProgress />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box className={classes.videoWrapper} >
                      <VideoRecorder
                        showReplayControls
                        replayVideoAutoplayAndLoopOff
                        countdownTime={0}

                        renderActions={renderActions}
                        renderDisconnectedView={() => <DisconnectedView />}
                        renderLoadingView={() => <LoadingView />}
                        renderUnsupportedView={() => <UnsupportedView />}

                        onRecordingComplete={onRecordingComplete} />
                    </Box>

                    <Box className={classes.actions} >
                      {
                        !isRecording && (
                          <>
                            {
                              isBlobAvailable ? (
                                <Button
                                  size="large"
                                  variant="contained"
                                  className={classes.recordBtn}
                                  onClick={reRecordHandler} >
                                  {t("cloudinary:cloudinaryRecordVideoReRecordBtn")}
                                </Button>
                              ) : (
                                <Button
                                  size="large"
                                  variant="contained"
                                  className={classes.recordBtn}
                                  onClick={recordHandler} >
                                  {t("cloudinary:cloudinaryRecordVideoRecordBtn")}
                                </Button>
                              )
                            }
                          </>
                        )
                      }

                      {
                        isRecording && (
                          <>
                            <Box>
                              <Typography className={classes.timer} >
                                {timerMinutes}:{timerSeconds}
                              </Typography>

                              <Button
                                size="large"
                                variant="contained"
                                className={classes.stopRecordingBtn}
                                onClick={stopRecordHandler} >
                                {t("cloudinary:cloudinaryRecordVideoStopRecordingBtn")}
                              </Button>
                            </Box>
                          </>
                        )
                      }

                      {
                        blob && !isRecording && isBlobAvailable && (
                          <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            className={classes.uploadBtn}
                            onClick={uploadVideoHandler} >
                            {t("cloudinary:cloudinaryRecordVideoUploadBtn")}
                          </Button>
                        )
                      }
                    </Box>
                  </>
                )
              }
            </Box>

            <Box className={classes.footer} ></Box>
          </Box>
        </Container>
      </Modal>
    </>
  );

};

export default CloudinaryVideoRecorder;
