import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AnalyticsIcon from '@material-ui/icons/AssessmentOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import ExtensionOutlinedIcon from '@material-ui/icons/ExtensionOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import LocalLibraryIcon from '@material-ui/icons/LocalLibraryOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import StarIcon from '@material-ui/icons/Star';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';

import { ADMIN, CANDIDATE, COREADMIN, CONSULTANT, CONTACT, SYSTEMADMIN } from './../../constants/roles';
import { TA, TA_PRO } from './../../constants/subscriptionPlansIds';

const coreAndSystemAdminFullSubscriptionConfigItems = [
	{
		translationName: 'consultancies',
		href: '/dashboard',
		icon: HomeIcon
	},
	{
		translationName: 'systemadmins',
		href: '/dashboard/system-admins',
		icon: SupervisorAccountOutlinedIcon
	},
	{
		translationName: 'allusers',
		href: '/dashboard/all-users',
		icon: PeopleOutlineIcon
	},
	{
		translationName: 'allcampaigns',
		href: '/dashboard/all-campaigns',
		icon: ChatOutlinedIcon
	},
	{
		translationName: 'talentAlerts',
		href: '/dashboard/talent-alerts',
		icon: StarIcon
	},
	{
		translationName: 'allemployers',
		href: '/dashboard/all-employers',
		icon: BusinessIcon
	},
	{
		translationName: 'modules',
		href: '/dashboard/modules',
		icon: ExtensionOutlinedIcon
	},
	{
		translationName: 'settings',
		href: '/dashboard/settings',
		icon: SettingsIcon
	},
	{
		translationName: 'myprofile',
		href: '/dashboard/my-profile',
		icon: AccountCircleOutlinedIcon
	},
	{
		translationName: 'analytics',
		href: '/dashboard/system-admin-analytics',
		icon: AnalyticsIcon
	},
	{
		translationName: 'retainedrecruiteracademy',
		href: '/dashboard/retained-recruiter-academy',
		icon: LocalLibraryIcon
	}
];

const adminFullSubscriptionConfigItems = [
	{
		translationName: 'campaigns',
		href: '/dashboard',
		icon: HomeIcon
	},
	{
		translationName: 'talentAlerts',
		href: '/dashboard/talent-alerts',
		icon: StarIcon
	},
	{
		translationName: 'candidates',
		href: '/dashboard/candidates',
		icon: RecentActorsIcon
	},
	{
		translationName: 'employers',
		href: '/dashboard/employers',
		icon: BusinessIcon
	},
	{
		translationName: 'consultancymanagement',
		href: '/dashboard/consultancy-management',
		icon: WorkOutlineOutlinedIcon
	},
	{
		translationName: 'myprofile',
		href: '/dashboard/my-profile',
		icon: AccountCircleOutlinedIcon
	},
	{
		translationName: 'retainedrecruiteracademy',
		href: '/dashboard/retained-recruiter-academy',
		icon: LocalLibraryIcon
	}
];

const adminTAPSubscriptionConfigItems = [
	{
		translationName: 'talentAlerts',
		href: '/dashboard/talent-alerts',
		icon: StarIcon
	},
	{
		translationName: 'vacancies',
		href: '/dashboard/vacancies',
		icon: HomeIcon
	},
	{
		translationName: 'candidates',
		href: '/dashboard/candidates',
		icon: RecentActorsIcon
	},
	{
		translationName: 'employers',
		href: '/dashboard/employers',
		icon: BusinessIcon
	},
	{
		translationName: 'consultancymanagement',
		href: '/dashboard/consultancy-management',
		icon: WorkOutlineOutlinedIcon
	},
	{
		translationName: 'myprofile',
		href: '/dashboard/my-profile',
		icon: AccountCircleOutlinedIcon
	}
];

const adminTASubscriptionConfigItems = [
	{
		translationName: 'talentAlerts',
		href: '/dashboard/talent-alerts',
		icon: StarIcon
	},
	{
		translationName: 'candidates',
		href: '/dashboard/candidates',
		icon: RecentActorsIcon
	},
	{
		translationName: 'employers',
		href: '/dashboard/employers',
		icon: BusinessIcon
	},
	{
		translationName: 'consultancymanagement',
		href: '/dashboard/consultancy-management',
		icon: WorkOutlineOutlinedIcon
	},
	{
		translationName: 'myprofile',
		href: '/dashboard/my-profile',
		icon: AccountCircleOutlinedIcon
	}
];

const consultantFullSubscriptionConfigItems = [
	{
		translationName: 'campaigns',
		href: '/dashboard',
		icon: HomeIcon
	},
	{
		translationName: 'talentAlerts',
		href: '/dashboard/talent-alerts',
		icon: StarIcon
	},
	{
		translationName: 'candidates',
		href: '/dashboard/candidates',
		icon: RecentActorsIcon
	},
	{
		translationName: 'employers',
		href: '/dashboard/employers',
		icon: BusinessIcon
	},
	{
		translationName: 'myprofile',
		href: '/dashboard/my-profile',
		icon: AccountCircleOutlinedIcon
	},
	{
		translationName: 'retainedrecruiteracademy',
		href: '/dashboard/retained-recruiter-academy',
		icon: LocalLibraryIcon
	}
];

const consultantTAPSubscriptionConfigItems = [
	{
		translationName: 'talentAlerts',
		href: '/dashboard/talent-alerts',
		icon: StarIcon
	},
	{
		translationName: 'vacancies',
		href: '/dashboard/vacancies',
		icon: HomeIcon
	},
	{
		translationName: 'employers',
		href: '/dashboard/employers',
		icon: BusinessIcon
	},
	{
		translationName: 'myprofile',
		href: '/dashboard/my-profile',
		icon: AccountCircleOutlinedIcon
	}
];

const consultantTASubscriptionConfigItems = [
	{
		translationName: 'talentAlerts',
		href: '/dashboard/talent-alerts',
		icon: StarIcon
	},
	{
		translationName: 'employers',
		href: '/dashboard/employers',
		icon: BusinessIcon
	},
	{
		translationName: 'myprofile',
		href: '/dashboard/my-profile',
		icon: AccountCircleOutlinedIcon
	}
];

const candidateFullSubscriptionConfigItems = [
	{
		translationName: 'mycampaigns',
		href: '/dashboard',
		icon: HomeIcon
	},
	{
		translationName: 'myprofile',
		href: '/dashboard/my-profile',
		icon: AccountCircleOutlinedIcon
	}
];

const candidateTAPSubscriptionConfigItems = [
	{
		translationName: 'talentAlerts',
		href: '/dashboard/talent-alerts',
		icon: StarIcon
	},
	{
		translationName: 'vacancies',
		href: '/dashboard/vacancies',
		icon: HomeIcon
	},
	{
		translationName: 'myprofile',
		href: '/dashboard/my-profile',
		icon: AccountCircleOutlinedIcon
	}
];

const candidateTASubscriptionConfigItems = [
	{
		translationName: 'talentAlerts',
		href: '/dashboard/talent-alerts',
		icon: StarIcon
	},
	{
		translationName: 'myprofile',
		href: '/dashboard/my-profile',
		icon: AccountCircleOutlinedIcon
	}
];

const contactFullSubscriptionConfigItems = [
	{
		translationName: 'campaigns',
		href: '/dashboard',
		icon: HomeIcon
	},
	{
		translationName: 'myprofile',
		href: '/dashboard/my-profile',
		icon: AccountCircleOutlinedIcon
	}
];

const contactTASubscriptionConfigItems = [
	{
		translationName: 'myprofile',
		href: '/dashboard/my-profile',
		icon: AccountCircleOutlinedIcon
	}
];

export default function getNavConfig(session) {
	if (session) {
		switch (session.roleType) {
			case COREADMIN:
			case SYSTEMADMIN:
				return [{ items: coreAndSystemAdminFullSubscriptionConfigItems }];
			case ADMIN:
				if (session.consultancySubscriptionPlanId === TA) {
					return [{ items: adminTASubscriptionConfigItems }];
				}

				if (session.consultancySubscriptionPlanId === TA_PRO) {
					return [{ items: adminTAPSubscriptionConfigItems }];
				}

				return [{ items: adminFullSubscriptionConfigItems }];
			case CONSULTANT:
				if (session.consultancySubscriptionPlanId === TA) {
					return [{ items: consultantTASubscriptionConfigItems }];
				}

				if (session.consultancySubscriptionPlanId === TA_PRO) {
					return [{ items: consultantTAPSubscriptionConfigItems }];
				}

				return [{ items: consultantFullSubscriptionConfigItems }];
			case CANDIDATE:
				if (session.consultancySubscriptionPlanId === TA) {
					return [{ items: candidateTASubscriptionConfigItems }];
				}

				if (session.consultancySubscriptionPlanId === TA_PRO) {
					return [{ items: candidateTAPSubscriptionConfigItems }];
				}

				return [{ items: candidateFullSubscriptionConfigItems }];
			case CONTACT:
				if (session.consultancySubscriptionPlanId === TA || session.consultancySubscriptionPlanId === TA_PRO) {
					return [{ items: contactTASubscriptionConfigItems }];
				}

				return [{ items: contactFullSubscriptionConfigItems }];
			default:
		}
	}

	return [{ items: [] }];
}
