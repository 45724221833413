import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  CircularProgress
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import {
  UPDATE_CANDIDATE_PROFILE,
  UPDATE_CANDIDATE_PROFILE_PICTURE,
  UPDATE_VIDEO_PROCCESSING
} from "src/actions/candidateCampaignActions";

import * as candidateApi from './../api/candidateApi';
import * as talentAlertApi from './../api/talentAlertApi';
import * as campaignApi from './../api/campaignApi';
import * as videoAssessmentApi from './../api/videoAssessmentApi';

import missing_avatar from "src/assets/images/missing_avatar.svg";
import CountrySelect from "src/components/CountrySelect";
import CustomFileDropzone from "src/components/CustomFileDropzone";
import { NOT_SUBMITTED } from "src/constants/candidateStatusIds";
import { CANDIDATE } from "src/constants/roles";
import RequiredCandidateDocuments from "src/views/Campaigns/CampaignDetail/CampaignDocuments/RequiredCandidateDocuments";
import validate from "validate.js";
import VideoModal from "src/views/CandidateCampaignDetail/CandidateDetails/VideoUploadModal";
import { BlockHeader } from "./LayoutComponents/BlockHeader";
import { CardButton } from "./LayoutComponents/CardButton";
import InfoIcon from "@material-ui/icons/Info";

import CloudinaryWidget from './CloudinaryWidget';
import VideoAssessmentReview from './VideoAssessment/VideoAssessmentReview';

import { UPDATE_CAMPAIGN } from './../actions/campaignActions';

import { useCampaignOrVacancyTranslationPrefix } from './../utils/useSubscriptionPrefix';

import { VIDEO_INTRODUCTION_ID } from './../constants/moduleIds';

//TODO:Translate this page

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  gridRow: {
    marginTop: "20px",
  },
  consultancyLogo: {
    height: "50px",
    width: "auto",
  },
  inputRow: {
    alignItems: "center",
  },
  headerRow: {
    textAlign: "right",
    padding: "0px 12px",
  },
  questionsAndAnswers: {
    padding: "10px 0px",
  },
  question: {
    marginBottom: "10px",
  },
  candidateStatusTooltip: { marginLeft: "10px" },
  tooltip: {
    textAlign: "center",
    padding: 2,
    margin: 0,
  },
  loader: {
    minWidth: '200px',
    minHeight: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default function ({ talentAlert, ...rest }) {
  const classes = useStyles();
  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();
  const [t] = useTranslation([
    "common",
    "validation",
    "snackbar",
    "labels",
    "campaignCandidates",
    "tooltips",
    "vacancies"
  ]);
  const history = useHistory();

  const profileData = useSelector(
    (state) => state.candidateCampaign.candidateProfile
  );
  const { campaignId, candidateId } = useSelector((state) => state.candidateCampaign.candidateProfile);
  const [candidateProfileVideo, setCandidateProfileVideo] = useState(profileData.candidateProfileVideo ? profileData.candidateProfileVideo : null);
  const { campaignModuleIds } = useSelector((state) => state.campaign);

  const schema = {
    candidateStatus: {
      presence: { allowEmpty: false, message: t("common:required") },
    },
    firstName: {
      presence: { allowEmpty: false, message: t("common:required") },
      length: {
        maximum: 64,
      },
    },
    lastName: {
      presence: { allowEmpty: false, message: t("common:required") },
      length: {
        maximum: 64,
      },
    },
    country: {
      presence: { allowEmpty: false, message: t("common:required") },
    }
  };

  if (talentAlert) {
    schema.jobTitle = {
      presence: { allowEmpty: false, message: t("common:required") },
      length: {
        maximum: 64
      }
    };
  }

  if (!talentAlert) {
    schema.postalCode = {
      presence: { allowEmpty: false, message: t("common:required") }
    };
  }

  const candidateStatusOptions = [
    {
      key: 'notSubmitted',
      value: 1
    },
    {
      key: 'submitted',
      value: 2
    },
    {
      key: talentAlert ? 'live' : 'shortlisted',
      value: 3
    }
  ];

  if (!talentAlert) {
    candidateStatusOptions.push(
      {
        key: 'firstInterview',
        value: 4
      },
      {
        key: 'furtherInterview',
        value: 5
      },
      {
        key: 'rejected',
        value: 6
      },
      {
        key: 'offerMade',
        value: 7
      }
    );
  }

  candidateStatusOptions.push({
    key: 'hidden',
    value: 8
  });

  const candidateCampaign = useSelector((state) => state.candidateCampaign);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const consultancyId = useSelector((state) => state.session.consultancyId);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      ...profileData,
      questions: candidateCampaign.campaignKeyCompetencyQuestions?.questions
    },
    touched: {
      firstName: false,
      lastName: false,
      jobTitle: false,
      postalCode: false,
      country: false
    },
    errors: {},
  });
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [videoAssessment, setVideoAssessment] = useState();
  const [videoAssessmentInterview, setVideoAssessmentInterview] = useState();

  const handleChange = (event) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleAnswerChange = (event, index) => {
    event.preventDefault();
    let questions = [...formState.values.questions];
    questions[index].answer = event.target.value;
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        questions,
      },
    }));
  };

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  const handleCountryChange = (value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        country: value,
      },
      touched: {
        ...prevFormState.touched,
        country: true,
      },
    }));
  };

  const saveDetails = (event) => {
    event.preventDefault();

    const touched = Object.keys(formState.touched)
      .reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {});

    setFormState({
      ...formState,
      touched
    });
    
    const errors = validate(formState.values, schema);

    if (errors) {
      return;
    }

    return candidateApi.updateCandidateProfile(
      formState.values,
      consultancyId,
      profileData.candidateId,
      profileData.campaignId
    )
      .then((response) => {
        dispatch({
          type: UPDATE_CANDIDATE_PROFILE,
          candidateProfile: formState.values,
        });
        if (
          roleType === CANDIDATE &&
          profileData.candidateStatus === NOT_SUBMITTED
        ) {
          enqueueSnackbar(t("snackbar:profileSubmitted"), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(t("snackbar:updateCandidateProfile"), {
            variant: "success",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:failedToUpdateProfile"), {
          variant: "error",
        });
      });
  };

  const roleType = useSelector((state) => state.session.roleType);

  const handleUploadCandidateHeadshot = (value) => {
    const promise = talentAlert ?
    talentAlertApi.uploadCandidateProfilePicture(profileData.campaignId, profileData.candidateId, consultancyId, value) :
    candidateApi.uploadCandidateProfilePicture(profileData.campaignId, profileData.candidateId, value);
    promise.then((response) => {
      dispatch({ type: UPDATE_CANDIDATE_PROFILE_PICTURE, profilePicture: response.data });
      enqueueSnackbar(t("snackbar:candidatePictureSuccess"), { variant: "success" });
    })
    .catch((error) => {
      console.error(error);
      enqueueSnackbar(t("snackbar:candidatePictureFailure"), { variant: "error" });
    });
  };

  const handleUploadVideoProfile = (videoInfo) => {
    const uploadOptions = {
      params: {
        campaignId: rest.campaignId,
        candidateId: rest.candidateId
      }
    };

    const data = {
      secure_url: videoInfo.secure_url,
      original_filename: videoInfo.original_filename,
      resource_type: videoInfo.resource_type,
      public_id: videoInfo.public_id,
      duration: videoInfo.duration
    };

    candidateApi.cloudinaryWidgetUploadProfileVideo(data, uploadOptions)
      .then(response => {
        setCandidateProfileVideo(response.data);
        handleModalClose();
      })
      .catch(() => {
        enqueueSnackbar('Error during upload, please try again!', {
          variant: 'error'
        });

        dispatch({
          type: UPDATE_VIDEO_PROCCESSING,
          videoProcessing: true
        });

        handleModalClose();
      });
  };

  const handleModalOpen = () => setModal(true);
  const handleModalClose = () => setModal(false);

  useEffect(() => {
    setIsLoading(true);
    if (!talentAlert) {
      campaignApi.getCampaignDetails(rest.consultancyId, rest.campaignId)
        .then(response => {
          dispatch({
            type: UPDATE_CAMPAIGN,
            campaign: response.data
          });
          setIsLoading(false);
        })
        .catch(err => {
          console.log(err);
          history.push('/dashboard');
        });
    };
    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getVideoAssessmentAndVideoAssessmentInterview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, candidateId]);

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  const getVideoAssessmentAndVideoAssessmentInterview = () => {
    videoAssessmentApi.getVideoAssessmentByCampaignId(campaignId)
      .then((response) => {
        setVideoAssessment(response.data);
        return videoAssessmentApi.getVideoAssessmentInterviewByCandidate(candidateId, response.data.id);
      })
      .then((response) => {
        setVideoAssessmentInterview(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (isLoading) {
    return (
      <Grid container spacing={3} className={classes.loader} >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <BlockHeader card header={t("labels:manageAvatar")} />
                <CustomFileDropzone
                  format="image"
                  type="single"
                  uploadFunction={handleUploadCandidateHeadshot}
                  placeHolderImage={
                    profileData.profilePicture || missing_avatar
                  }
                />
              </CardContent>
            </Card>
            {
              (talentAlert || campaignModuleIds?.includes(VIDEO_INTRODUCTION_ID)) && (
                <Card style={{ marginTop: 16 }}>
                  <CardContent>
                    <BlockHeader card header={t(`${campaignsPrefix}:uploadAVideo`)} />

                    {profileData.videoProcessing ? (
                      <>{t("newTranslations:videoProcessing")}</>
                    ) : (
                      <>
                        <CloudinaryWidget onSuccess={handleUploadVideoProfile} />

                        {candidateProfileVideo?.url && (
                          <Button
                            style={{ marginTop: 16 }}
                            color="primary"
                            fullWidth
                            variant="outlined"
                            size="large"
                            onClick={handleModalOpen}
                          >
                            {t("campaigns:viewVideo")}
                          </Button>
                        )}
                      </>
                    )}
                  </CardContent>
                </Card>
              )
            }

            <Card style={{ marginTop: 16 }}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} style={{ textAlign: "left" }}>
                    <RequiredCandidateDocuments
                      campaignRequiredDocuments={
                        profileData.campaignRequiredDocuments
                      }
                      candidateView={true}
                      guestView={rest?.guestView}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            {
              videoAssessment && videoAssessmentInterview && videoAssessmentInterview.completedAt && (
                  <Card style={{ marginTop: 16 }}>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <VideoAssessmentReview videoAssessmentInteview={ videoAssessmentInterview } videoAssessmentReset={ getVideoAssessmentAndVideoAssessmentInterview } />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
              )
            }
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <BlockHeader card padding header={t("labels:manageDetails")} />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {roleType === CANDIDATE ? (null) : (
                      <Grid container spacing={3} className={classes.inputRow}>
                        <Grid item xs={12} md={3} style={{ display: "flex" }}>
                          <Typography variant="h5">
                            {t("labels:status")}
                          </Typography>
                          <Tooltip
                            enterTouchDelay={1}
                            classes={{
                              tooltip: classes.tooltip,
                            }}
                            className={classes.candidateStatusTooltip}
                            title={ talentAlert ? t("tooltips:taManuallyUpdateCandidateStatus") : t("tooltips:manuallyUpdateCandidateStatus") }
                          >
                            <InfoIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            className={classes.formControl}
                          >
                            <Select
                              value={formState.values.candidateStatus}
                              name="candidateStatus"
                              onChange={handleChange}
                              label={t("common:status")}
                              disabled={rest?.guestView} >
                              {
                                candidateStatusOptions.map((cso) => (
                                  <MenuItem value={ cso.value }>
                                    { t(`campaignCandidates:${cso.key}`) }
                                  </MenuItem>
                                ))
                              }
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}

                    {
                      !talentAlert && (
                        <Grid container spacing={3} className={classes.inputRow}>
                          <Grid item xs={12} md={3}>
                            <Typography variant="h5">
                              {t(`common:salutationLabel`)}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={9}>
                            <TextField
                              fullWidth
                              name="salutation"
                              variant="outlined"
                              onChange={handleChange}
                              error={hasError("salutation")}
                              value={formState.values.salutation || ""}
                              helperText={
                                hasError("salutation")
                                  ? t(
                                    `validation:${formState.errors.salutation[0]}`
                                  )
                                  : null
                              }
                              disabled={rest?.guestView}
                            />
                          </Grid>
                        </Grid>
                      )
                    }

                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:firstNameLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="firstName"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("firstName")}
                          value={formState.values.firstName || ""}
                          helperText={
                            hasError("firstName")
                              ? t(`validation:${formState.errors.firstName[0]}`)
                              : null
                          }
                          disabled={rest?.guestView}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:lastNameLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="lastName"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("lastName")}
                          value={formState.values.lastName || ""}
                          helperText={
                            hasError("lastName")
                              ? t(`validation:${formState.errors.lastName[0]}`)
                              : null
                          }
                          disabled={rest?.guestView}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t('newTranslations:jobTitle')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="jobTitle"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("jobTitle")}
                          value={formState.values.jobTitle || ""}
                          helperText={
                            hasError("jobTitle")
                              ? t(`validation:${formState.errors.jobTitle[0]}`)
                              : null
                          }
                          disabled={rest?.guestView}
                        />
                      </Grid>
                    </Grid>

                    {
                      !talentAlert && (
                        <>
                          <Grid container spacing={3} className={classes.inputRow}>
                            <Grid item xs={12} md={3}>
                              <Typography variant="h5">
                                {t(`common:telephoneLabel`)}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={9}>
                              <TextField
                                fullWidth
                                name="telephone"
                                variant="outlined"
                                onChange={handleChange}
                                error={hasError("telephone")}
                                value={formState.values.telephone || ""}
                                helperText={
                                  hasError("telephone")
                                    ? t(`validation:${formState.errors.telephone[0]}`)
                                    : null
                                }
                                disabled={rest?.guestView}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3} className={classes.inputRow}>
                            <Grid item xs={12} md={3}>
                              <Typography variant="h5">
                                {t(`common:addressLabel1`)}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={9}>
                              <TextField
                                fullWidth
                                name="addressLine1"
                                variant="outlined"
                                onChange={handleChange}
                                error={hasError("addressLine1")}
                                value={formState.values.addressLine1 || ""}
                                helperText={
                                  hasError("addressLine1")
                                    ? t(
                                      `validation:${formState.errors.addressLine1[0]}`
                                    )
                                    : null
                                }
                                disabled={rest?.guestView}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3} className={classes.inputRow}>
                            <Grid item xs={12} md={3}>
                              <Typography variant="h5">
                                {t(`common:addressLabel2`)}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={9}>
                              <TextField
                                fullWidth
                                name="addressLine2"
                                variant="outlined"
                                onChange={handleChange}
                                error={hasError("addressLine2")}
                                value={formState.values.addressLine2 || ""}
                                helperText={
                                  hasError("addressLine2")
                                    ? t(
                                      `validation:${formState.errors.addressLine2[0]}`
                                    )
                                    : null
                                }
                                disabled={rest?.guestView}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )
                    }

                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">{t(`common:City`)}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          name="city"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("city")}
                          value={formState.values.city || ""}
                          helperText={
                            hasError("city")
                              ? t(`validation:${formState.errors.city[0]}`)
                              : null
                          }
                          disabled={rest?.guestView}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography variant="h5">
                          {t(`common:postcodeLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          fullWidth
                          name="postalCode"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("postalCode")}
                          value={formState.values.postalCode || ""}
                          helperText={
                            hasError("postalCode")
                              ? t(
                                `validation:${formState.errors.postalCode[0]}`
                              )
                              : null
                          }
                          disabled={rest?.guestView}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:countryLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <CountrySelect
                          handleCountryChange={handleCountryChange}
                          value={formState.values.country}
                          disabled={rest?.guestView}
                        />
                      </Grid>
                    </Grid>
                    <CardButton save btnFunc={saveDetails} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {formState.values.questions ? (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  {formState.values.questions?.map((question, index) => (
                    <div className={classes.questionsAndAnswers}>
                      <Typography variant="h5" className={classes.question}>
                        {question.question}
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={question.answer || ""}
                        rows={3}
                        multiline
                        onChange={(event) => handleAnswerChange(event, index)}
                      />
                    </div>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <VideoModal
        open={modal}
        video={candidateProfileVideo}
        onClose={handleModalClose}
        campaignId={profileData.campaignId}
        candidateStatus={profileData.candidateStatus}
        candidateId={profileData.candidateId}
      />
    </Grid>
  );
}
