import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import { useFormik } from 'formik';

import { useSnackbar } from 'notistack';

import { Box, Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import * as Yup from 'yup';

import * as talentAlertApi from './../api/talentAlertApi';

const useStyles = makeStyles((theme) => ({
    fields: {
        margin: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            flexGrow: 1,
            margin: theme.spacing(1)
        }
    },
    fieldText: {
        margin: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            flexGrow: 1,
            margin: theme.spacing(1),
        },
        marginBottom: '20px'
    },
    submitButton: {
        width: '50%',
        height: '3rem',
        margin: '1rem auto 0.5rem',
        borderRadius: 5,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main)
    },
    progressCircle: {
        color: theme.palette.primary.contrastText
    },
    message: {
        whiteSpace: 'pre-wrap'
    },
    recruiterDetails: {
        margin: theme.spacing(3)
    }
}));

function ContactRecruiterForm({
    candidate,
    buttonColour,
    className,
    campaignId,
    authenticated,
    ...rest
}) {
    const classes = useStyles();
    const [t] = useTranslation(['common', 'login', 'validation', 'snackbar']);
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(true);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const [recruiterEmail, setRecruiterEmail] = useState('');
    const [candidateNames, setCandidateNames] = useState('');
    const [candidateId, setCandidateId] = useState(-1);

    const schema = Yup.object({
        email: Yup.string().required(t('validation:emailIsRequired')),
        phone: Yup.string(),
        company: Yup.string(),
        name: Yup.string().required(t('validation:nameIsRequired')),
        message: Yup.string()
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            phone: '',
            company: '',
            name: '',
            message: ''
        },
        validateOnMount: true,
        validationSchema: schema,
        onSubmit: (values) => {
            setLoading(true);

            const formData = {
                ...values,
                recruiterEmail,
                candidate: candidateNames,
                candidateId
            };

            talentAlertApi.contactRecruiter(formData, campaignId)
                .then((response) => {
                    if (response.status === 200) {
                        setFormSubmitted(true);
                    }

                    setLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    enqueueSnackbar(`${t('validation:messageFailed')}: ${t(`snackbar:${error.message}`)}`, { variant: 'error' });
                    setLoading(false);
                });
        }
    });

    useEffect(() => {
        setLoading(true);

        if (authenticated) {
            formik.setValues({
                email: candidate.contact.email,
                phone: '',
                company: '',
                name: `${candidate.contact.profile.firstName} ${candidate.contact.profile.lastName}`,
                message: `Dear ${candidate.campaignManager.profile.firstName},\n \nI am interested in learning more about ${candidate.firstName}. Please can we arrange a call to discuss more?\n \nKind regards,\n \n${candidate.contact.profile.firstName} ${candidate.contact.profile.lastName}`
            });

            setCandidateNames(`${candidate.firstName} ${candidate.lastName}`);
        } else {
            formik.setValues({
                ...formik.values,
                message: `Dear ${candidate.campaignManager.profile.firstName},\n \nI am interested in learning more about one of your candidates. Please can you grant me access to view more?\n \nKind regards,\n \n${formik.values.name ? formik.values.name : ''}`
            });
        }

        setRecruiterEmail(candidate.campaignManager.user.email);
        setCandidateId(candidate.candidateId);
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        loading ? (
            "Loading..."
        ) : (
            formSubmitted ? (
                <Typography>
                    Thank you for your interest. Your recruiter {candidate.campaignManager.profile.firstName} {candidate.campaignManager.profile.lastName} will be in touch soon to discuss.
                </Typography>
            ) : (
                <form
                    {...rest}
                    className={clsx(classes.root, className)}
                    onSubmit={ formik.handleSubmit }
                >
                    <div className={classes.fieldText}>
                        {!authenticated && (
                            <Typography>
                                Contact {candidate.campaignManager.profile.firstName} {candidate.campaignManager.profile.lastName} to request access for this Candidate.
                            </Typography>
                        )}
                    </div>
                    {loading ? (
                        "loading..."
                    ) : (
                        <>
                            <div className={classes.recruiterDetails}>
                                <Typography>
                                    Recruiter: {candidate.campaignManager.profile.firstName} {candidate.campaignManager.profile.lastName}
                                </Typography>
                                <Typography>
                                    Email: {candidate.campaignManager.user.email}
                                </Typography>
                            </div>
                            <div className={classes.fields}>
                                <TextField
                                    fullWidth
                                    label={t("common:Email Address")}
                                    name="email"
                                    variant="outlined"
                                    value={ formik.values.email }
                                    error={ formik.touched.email && !!formik.errors.email }
                                    helperText={ formik.touched.email ? formik.errors.email : null }
                                    onChange={ formik.handleChange }
                                    onBlur={ formik.handleBlur }
                                />
                            </div>
                            <div className={classes.fields}>
                                <TextField
                                    fullWidth
                                    label={t("common:Phone Number")}
                                    name="phone"
                                    variant="outlined"
                                    value={ formik.values.phone }
                                    onChange={ formik.handleChange }
                                />
                            </div>
                            {!authenticated && (
                                <>
                                    <div className={classes.fields}>
                                        <TextField
                                            fullWidth
                                            label={t("common:Company")}
                                            name="company"
                                            variant="outlined"
                                            value={ formik.values.company }
                                            onChange={ formik.handleChange }
                                        />
                                    </div>
                                    <div className={classes.fields}>
                                        <TextField
                                            fullWidth
                                            label={t("common:Name")}
                                            name="name"
                                            variant="outlined"
                                            value={ formik.values.name }
                                            error={ formik.touched.name && !!formik.errors.name }
                                            helperText={ formik.touched.name ? formik.errors.name : null }
                                            onChange={ formik.handleChange }
                                            onBlur={ formik.handleBlur }
                                        />
                                    </div>
                                </>
                            )}
                            <div className={classes.fields}>
                                <TextField
                                    fullWidth
                                    label={t("common:Message")}
                                    name="message"
                                    variant="outlined"
                                    multiline
                                    rows={8}
                                    className={classes.message}
                                    value={ formik.values.message }
                                    onChange={ formik.handleChange }
                                />
                            </div>
                            <Box display="flex" flexDirection="column" justifyContent="center">
                                <Button
                                    className={classes.submitButton}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    disabled={ !formik.isValid }
                                >
                                    {loading ? (
                                        <CircularProgress className={classes.progressCircle} size={25} />
                                    ) : (
                                        t("login:Send Message")
                                    )}
                                </Button>
                            </Box>
                        </>
                    )}
                </form >
            )
        )
    );
}

export default ContactRecruiterForm;
